import { useCallback, useState, useEffect, useRef } from 'react';
import { listingService } from '../services/listingService';
import { mapService } from '../services/mapService';
import { MOVE_END_DELAY } from '../constants';

export const useMapData = ({ map, token, setLoading, isSearchActive }) => {
  const [savedListings, setSavedListings] = useState([]);
  const [regularListings, setRegularListings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  
  const mapLoadedRef = useRef(false);
  const lastMoveTimestamp = useRef(0);
  const moveEndTimer = useRef(null);
  const isSearchActiveRef = useRef(isSearchActive);
  const isFetchingRef = useRef(false);
  const searchResultsJustLoadedRef = useRef(false);
  const ignoreNextMoveRef = useRef(false);
  const lastSearchStateChangeTime = useRef(Date.now());
  const initialFetchCompletedRef = useRef(false);

  // Function to check if we're on mobile
  const isMobileView = () => {
    return window.innerWidth <= 768; // Common mobile breakpoint
  };

  // Update search active ref when the prop changes
  useEffect(() => {
    const wasSearchActive = isSearchActiveRef.current;
    console.log('Search active state changed:', isSearchActive, 'was:', wasSearchActive);
    isSearchActiveRef.current = isSearchActive;
    lastSearchStateChangeTime.current = Date.now();
    
    if (isSearchActive && !wasSearchActive) {
      // Entering search mode
      console.log('Entering search mode, clearing regular listings');
      setRegularListings([]);
      searchResultsJustLoadedRef.current = true;
      ignoreNextMoveRef.current = true;
      
      // Cancel any pending move timer when entering search mode
      if (moveEndTimer.current) {
        clearTimeout(moveEndTimer.current);
        moveEndTimer.current = null;
      }
    } else if (!isSearchActive && wasSearchActive) {
      // Exiting search mode (including reset)
      console.log('Exiting search mode, preparing to fetch regular listings');
      searchResultsJustLoadedRef.current = false;
      ignoreNextMoveRef.current = false;
      lastSearchStateChangeTime.current = 0; // Reset the timer to allow immediate fetching
      
      // Clear the move timer to prevent any pending fetches
      if (moveEndTimer.current) {
        clearTimeout(moveEndTimer.current);
        moveEndTimer.current = null;
      }
    }
  }, [isSearchActive]);

  const fetchSavedListings = useCallback(async () => {
    if (!token || isFetchingRef.current) return;
    
    isFetchingRef.current = true;
    setIsLoading(true);
    try {
      const data = await listingService.fetchSavedListings(token);
      setSavedListings(data);
      return data;
    } catch (error) {
      console.error('Error fetching saved listings:', error);
      setError(error);
    } finally {
      isFetchingRef.current = false;
      setIsLoading(false);
    }
  }, [token]);

  const fetchListings = useCallback(async () => {
    // Don't fetch if map isn't ready or if we're fetching something else
    if (!map.current || !mapLoadedRef.current || isFetchingRef.current) {
      console.log('Map not ready or fetch in progress, skipping fetchListings');
      return;
    }

    // Skip fetching if in search mode or if search state recently changed
    // But allow the initial fetch to go through
    const timeSinceLastSearchChange = Date.now() - lastSearchStateChangeTime.current;
    if (!initialFetchCompletedRef.current) {
      console.log('Performing initial listings fetch');
    } else if (isSearchActiveRef.current || (timeSinceLastSearchChange < MOVE_END_DELAY && lastSearchStateChangeTime.current !== 0)) {
      console.log('Search mode active or recent change, skipping regular listings fetch');
      return;
    }

    isFetchingRef.current = true;
    setLoading?.(true);

    try {
      const currentBounds = map.current.getBounds();
      console.log('Fetching listings with bounds:', currentBounds.toString());

      const data = await listingService.fetchListingsInBounds(currentBounds);
      
      // Only update if we're still not in search mode
      if (!isSearchActiveRef.current && data.listings && Array.isArray(data.listings)) {
        console.log('Updating regular listings:', data.listings.length);
        setRegularListings(data.listings);
        if (map.current.getSource('listings')) {
          mapService.updateListings(map.current, data.listings, savedListings);
        }
      }
      initialFetchCompletedRef.current = true;
      return data;
    } catch (error) {
      console.error('Error fetching listings:', error);
      setError(error);
    } finally {
      isFetchingRef.current = false;
      setLoading?.(false);
    }
  }, [map, savedListings, setLoading]);

  const handleMoveEnd = useCallback(() => {
    // If we should ignore this move event
    if (ignoreNextMoveRef.current) {
      console.log('Ignoring map move due to search state change');
      ignoreNextMoveRef.current = false;
      return;
    }

    // If search results just loaded or search state recently changed, ignore the move event
    const timeSinceLastSearchChange = Date.now() - lastSearchStateChangeTime.current;
    if (searchResultsJustLoadedRef.current || timeSinceLastSearchChange < MOVE_END_DELAY) {
      console.log('Ignoring map move due to recent search state change');
      searchResultsJustLoadedRef.current = false;
      return;
    }

    // Immediately check if we're in search mode before setting any timers
    if (isSearchActiveRef.current) {
      console.log('Search active, ignoring map move');
      return;
    }

    if (moveEndTimer.current) {
      clearTimeout(moveEndTimer.current);
    }

    moveEndTimer.current = setTimeout(() => {
      // Re-check all conditions before fetching
      const currentTimeSinceSearchChange = Date.now() - lastSearchStateChangeTime.current;
      if (isSearchActiveRef.current || 
          searchResultsJustLoadedRef.current || 
          ignoreNextMoveRef.current ||
          currentTimeSinceSearchChange < MOVE_END_DELAY) {
        console.log('Search state changed or too recent, skipping fetch');
        return;
      }

      const currentTime = Date.now();
      if (currentTime - lastMoveTimestamp.current > MOVE_END_DELAY && 
          mapLoadedRef.current && 
          !isSearchActiveRef.current &&
          !isFetchingRef.current) {
        console.log('Map moved, fetching new listings');
        fetchListings();
      }
    }, MOVE_END_DELAY);
    
    lastMoveTimestamp.current = Date.now();
  }, [fetchListings]);

  // Modified map load handler
  useEffect(() => {
    if (!map.current) return;

    const handleMapLoad = async () => {
      console.log('Map load event triggered');
      setLoading(true);
      
      if (!mapLoadedRef.current) {
        mapLoadedRef.current = true;

        if (!map.current.getSource('listings')) {
          console.log('Initializing map sources');
          mapService.initializeSources(map.current);
        }
        
        // Sequential loading with proper checks
        if (!initialFetchCompletedRef.current) {
          try {
            // Wait for map to be fully ready AND initial resize
            await new Promise(resolve => {
              const checkMapReady = () => {
                const isMapReady = map.current && 
                                 map.current.isStyleLoaded() && 
                                 map.current.getSource('listings');
                
                // Check if container size is non-zero
                const container = map.current.getContainer();
                const hasSize = container.offsetWidth > 0 && container.offsetHeight > 0;

                if (isMapReady && hasSize) {
                  console.log('Map style, sources, and size fully loaded');
                  resolve();
                } else {
                  console.log('Waiting for map style, sources, or size...', {
                    isMapReady,
                    hasSize,
                    width: container.offsetWidth,
                    height: container.offsetHeight
                  });
                  requestAnimationFrame(checkMapReady);
                }
              };
              checkMapReady();
            });

            if (isMobileView()) {
              console.log('Mobile view detected, using viewport initialization');
              // Force a proper viewport setup for mobile
              const currentCenter = map.current.getCenter();
              const currentZoom = map.current.getZoom();
              
              // Force a slight pan to ensure viewport is properly initialized
              map.current.easeTo({
                center: [currentCenter.lng + 0.0001, currentCenter.lat],
                zoom: currentZoom,
                duration: 0,
                // After the pan, immediately return to original position and fetch
                callback: () => {
                  map.current.easeTo({
                    center: currentCenter,
                    zoom: currentZoom,
                    duration: 0,
                    callback: async () => {
                      // Now fetch regular listings
                      if (!isSearchActiveRef.current) {
                        console.log('Fetching initial regular listings after mobile viewport setup');
                        const regularListingsData = await fetchListings();
                        // Update map with both saved and regular listings at once
                        mapService.updateListings(map.current, regularListingsData?.listings || [], savedListings);
                      }
                      initialFetchCompletedRef.current = true;
                    }
                  });
                }
              });
            } else {
              console.log('Desktop view detected, proceeding with normal initialization');
              // On desktop, fetch regular listings and update map once
              if (!isSearchActiveRef.current) {
                console.log('Fetching initial regular listings');
                const regularListingsData = await fetchListings();
                // Update map with both saved and regular listings at once
                mapService.updateListings(map.current, regularListingsData?.listings || [], savedListings);
              }
              initialFetchCompletedRef.current = true;
            }

          } catch (error) {
            console.error('Error during initial data load:', error);
            setError(error);
          }
        }
      }
      setLoading(false);
    };

    // Handle resize events
    const handleResize = () => {
      if (map.current) {
        map.current.resize();
        
        // Only trigger re-evaluation if:
        // 1. Initial fetch not completed
        // 2. We're on mobile
        // 3. Not currently fetching
        // 4. Not in search mode
        if (!initialFetchCompletedRef.current && 
            isMobileView() && 
            !isFetchingRef.current && 
            !isSearchActiveRef.current) {
          console.log('Resize triggered on mobile before initial fetch, re-evaluating');
          handleMapLoad();
        }
      }
    };

    // Add resize listener with debounce to prevent rapid calls
    let resizeTimeout;
    const debouncedResize = () => {
      if (resizeTimeout) {
        clearTimeout(resizeTimeout);
      }
      resizeTimeout = setTimeout(handleResize, 250);
    };

    window.addEventListener('resize', debouncedResize);

    // Ensure we handle both the load event and already-loaded state
    if (map.current.loaded()) {
      console.log('Map already loaded on effect');
      handleMapLoad();
    } else {
      map.current.on('load', handleMapLoad);
    }

    map.current.on('moveend', handleMoveEnd);

    return () => {
      window.removeEventListener('resize', debouncedResize);
      if (resizeTimeout) {
        clearTimeout(resizeTimeout);
      }
      if (map.current) {
        map.current.off('load', handleMapLoad);
        map.current.off('moveend', handleMoveEnd);
      }
      if (moveEndTimer.current) {
        clearTimeout(moveEndTimer.current);
      }
    };
  }, [map, handleMoveEnd, fetchListings, fetchSavedListings, savedListings, setLoading]);

  return {
    savedListings,
    regularListings,
    fetchSavedListings,
    fetchListings,
    isLoading,
    error
  };
};
import { useState, useRef } from 'react';
import { DEFAULT_CENTER } from '../constants';

export const useMapState = () => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(DEFAULT_CENTER.lng);
  const [lat, setLat] = useState(DEFAULT_CENTER.lat);
  const [zoom, setZoom] = useState(DEFAULT_CENTER.zoom);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [popupInfo, setPopupInfo] = useState(null);
  const [popupScreenPosition, setPopupScreenPosition] = useState(null);
  const lastMoveTimestamp = useRef(0);
  const moveEndTimer = useRef(null);
  const mapLoadedRef = useRef(false);
  const currentBounds = useRef(null);

  const setMapLoadedState = (value) => {
    setMapLoaded(value);
    mapLoadedRef.current = value;
  };

  const updateCurrentBounds = () => {
    if (map.current) {
      currentBounds.current = map.current.getBounds();
      // Update coordinates to match current center
      const center = map.current.getCenter();
      console.log('Updating current bounds:', {
        bounds: {
          northeast: {
            lat: currentBounds.current.getNorthEast().lat,
            lng: currentBounds.current.getNorthEast().lng
          },
          southwest: {
            lat: currentBounds.current.getSouthWest().lat,
            lng: currentBounds.current.getSouthWest().lng
          }
        },
        center: {
          lat: center.lat,
          lng: center.lng
        },
        zoom: map.current.getZoom()
      });
      setLng(center.lng);
      setLat(center.lat);
      setZoom(map.current.getZoom());
    } else {
      console.log('Cannot update bounds - map not initialized');
    }
  };

  return {
    mapContainer,
    map,
    coordinates: { lng, lat, zoom },
    setCoordinates: { setLng, setLat, setZoom },
    mapLoaded,
    setMapLoadedState,
    mapLoadedRef,
    popup: {
      info: popupInfo,
      setInfo: setPopupInfo,
      screenPosition: popupScreenPosition,
      setScreenPosition: setPopupScreenPosition
    },
    moveState: {
      lastMoveTimestamp,
      moveEndTimer,
      updateCurrentBounds,
      currentBounds
    }
  };
};
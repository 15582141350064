import { useState, useCallback, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import Navbar from '../Navbar/Navbar.jsx';
import Footer from '../Footer/Footer.jsx';
import SearchPanel from '../SearchPanel/SearchPanel';
import Map from './map.jsx';
import styles from './map_page.module.css';
import { useMediaQuery } from '../Hooks/useMediaQuery';
import axios from '../utils/axios';
import { useAuth } from '../AuthContext';

function MapPage() {
  const { isLoggedIn, loading: authLoading } = useAuth();
  const [searchResults, setSearchResults] = useState(null);
  const [searchParams, setSearchParams] = useState(null);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [showingFavorites, setShowingFavorites] = useState(false);
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery('(max-width: 768px)');
  const all_results = 'true';

  // If authentication is still loading, show nothing to prevent flash
  if (authLoading) {
    return null;
  }

  // Redirect to login if user is not authenticated
  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }

  const handleSearchResults = (results, params) => {
    setSearchResults(results);
    setSearchParams(params);
    setIsSearchActive(!!results);
    setLoading(false);
  };

  const handleSearchStateChange = (isActive) => {
    setIsSearchActive(isActive);
  };

  const handleReset = () => {
    setSearchResults(null);
    setSearchParams(null);
    setIsSearchActive(false);
  };

  const handleFavoritesToggle = (showFavorites) => {
    setShowingFavorites(showFavorites);
  };

  return (
    <div className={styles.mapPage}>
      <Navbar />
      <main className={styles.mapContent}>
        <div className={styles.mapContainer}>
          <Map 
            searchResults={searchResults} 
            searchParams={searchParams} 
            isSearchActive={isSearchActive} 
            onReset={handleReset}
            showingFavorites={showingFavorites}
            setLoading={setLoading}
          />
        </div>
        <div className={styles.searchPanelContainer}>
          {isMobile && searchResults?.listings?.length > 0 && (
            <div className={styles.dragHandle}>
              <div className={styles.resultCount}>
                {searchResults.listings.length} results found
              </div>
            </div>
          )}
          <SearchPanel 
            onSearchResults={handleSearchResults}
            onSearchParamsChange={setSearchParams}
            onSearchStateChange={handleSearchStateChange}            
            onReset={handleReset}
            onFavoritesToggle={handleFavoritesToggle}
            setLoading={setLoading}
            all_results={all_results}
          />
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default MapPage;

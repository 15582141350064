'use client'

import { useState, useEffect, useCallback } from 'react'
import axios from '../utils/axios';
import { useAuth } from '../AuthContext'
import AsyncSelect from 'react-select/async'
import Select, { components as reactSelectComponents } from 'react-select'
import { Search } from 'lucide-react'
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import {
  Card,
  CardContent,
} from "@/components/ui/card"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"
import { ScrollArea } from "@/components/ui/scroll-area"
import { useToast } from "@/components/ui/use-toast"
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  TooltipProvider
} from "@/components/ui/tooltip"
import stateCountyData from './state_county_fips.json'
import styles from './SearchPanel.module.css'

type SearchParams = {
  searchTerm: string;
  county: string[];
  state: string[];
  city: string[];
  zipcode: string[];
  transactionType: string[];
  propertyType: string[];
  MinSquareFootage: string;
  MaxSquareFootage: string;
  MinAcres: string;
  MaxAcres: string;
}

type SavedSearch = {
  id: number;
  search_name: string;
  search_criteria: SearchParams;
}

// Add this type to help with state data typing
type StateData = {
  [key: string]: {
    state: string;
    abbreviation: string;
    state_FIPS: string;
    counties: Array<{
      county: string;
      fips: string;
    }>;
  };
};

export default function SearchPanel({
  onSearchResults,
  onApplySavedSearch,
  all_results = 'false',
  onSearchParamsChange,
  onSearchStateChange,
  onReset,
  onFavoritesToggle,
  hideViewFavorites = false
}: {
  onSearchResults: (results: any, params: SearchParams) => void;
  onApplySavedSearch?: (criteria: SearchParams) => void;
  all_results?: string;
  onSearchParamsChange?: (params: SearchParams) => void;
  onSearchStateChange?: (isSearching: boolean) => void;
  onReset?: () => void;
  onFavoritesToggle?: (showFavorites: boolean) => void;
  hideViewFavorites?: boolean;
}) {
  const [searchParams, setSearchParams] = useState<SearchParams>({
    searchTerm: '',
    county: [],
    state: [],
    city: [],
    zipcode: [],
    transactionType: [],
    propertyType: [],
    MinSquareFootage: '',
    MaxSquareFootage: '',
    MinAcres: '',
    MaxAcres: '',
  })
  const [savedSearches, setSavedSearches] = useState<SavedSearch[]>([])
  const [showSavedSearches, setShowSavedSearches] = useState(false)
  const [viewingFavorites, setViewingFavorites] = useState(false)
  const [shouldSearch, setShouldSearch] = useState(false)
  const [isButtonActive, setIsButtonActive] = useState(false)
  const { toast } = useToast()

  const { isLoggedIn, token, user } = useAuth()
  const isBasicUser = user && user.role_id === 3

  const transactionOptions = [
    { value: 'sale', label: 'Sale' },
    { value: 'lease', label: 'Lease' },
    { value: 'both', label: 'Both Sale and Lease' },
  ]

  const propertyOptions = [
    { value: 'retail', label: 'Retail' },
    { value: 'office', label: 'Office' },
    { value: 'industrial', label: 'Industrial' },
    { value: 'land', label: 'Land' },
    { value: 'medical', label: 'Medical' },
    { value: 'mixeduse', label: 'Mixed-Use' },
    { value: 'commercial', label: 'Commercial' },
  ]

  const [stateOptions, setStateOptions] = useState<any[]>([])
  const [countyOptions, setCountyOptions] = useState<any[]>([])

  useEffect(() => {
    // Preload state options with abbreviations
    const states = Object.entries(stateCountyData as StateData).map(([_, data]) => ({
      value: data.abbreviation,
      label: `${data.state} (${data.abbreviation})`
    }));
    setStateOptions(states);

    // Preload county options with "County" suffix instead of state abbreviation
    const counties = Object.entries(stateCountyData as StateData).flatMap(([_, data]) => 
      data.counties.map(county => ({
        value: `${county.county},${data.abbreviation}`,
        label: `${county.county} County, ${data.abbreviation}`,
        state: data.abbreviation
      }))
    );
    // Remove duplicates based on county name
    const uniqueCounties = Array.from(
      new Map(counties.map(county => [county.value, county])).values()
    );
    setCountyOptions(uniqueCounties);
  }, []);

  useEffect(() => {
    if (shouldSearch) {
      handleSubmit()
      setShouldSearch(false)
    }
  }, [shouldSearch])

  const loadOptions = useCallback(async (inputValue: string, field: string) => {
    if (inputValue.length < 2) return []

    try {
      const response = await axios.get(`search_controls/autocomplete/${field}`, {
        params: { query: inputValue },
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return response.data.map((item: any) => ({
        value: item[field],
        label: item[field]
      }))
    } catch (error) {
      console.error(`Error fetching ${field} suggestions:`, error)
      return []
    }
  }, [token])

  const validateSearchParams = () => {
    return Object.values(searchParams).some(value =>
      Array.isArray(value) ? value.length > 0 : value !== ''
    )
  }

  const handleSubmit = async () => {
    if (!validateSearchParams()) {
      toast({
        title: "Error",
        description: "Please select at least one search parameter.",
        variant: "destructive",
      })
      return
    }

    try {
      const response = await axios.get('search_controls/search', {
        params: { ...searchParams, all_results, page: 1 },
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      if (onSearchResults) {
        onSearchResults(response.data, searchParams)
      }
      if (onSearchParamsChange) {
        onSearchParamsChange(searchParams)
      }
      if (onSearchStateChange) {
        onSearchStateChange(true)
      }
    } catch (error) {
      console.error('Error performing search:', error)
      toast({
        title: "Error",
        description: "An error occurred while performing the search. Please try again.",
        variant: "destructive",
      })
    }
  }

  const handleMultiSelectChange = (selectedOptions: any, action: { name: string }) => {
    let newValue = []
    if (action.name === 'county') {
      newValue = selectedOptions ? selectedOptions.map((option: any) => option.value) : []
    } else {
      newValue = selectedOptions ? selectedOptions.map((option: any) => option.value) : []
    }
    
    const newParams = {
      ...searchParams,
      [action.name]: newValue
    }
    setSearchParams(newParams)
    
    // Check if all fields are empty
    const isEmpty = Object.values(newParams).every(value => 
      Array.isArray(value) ? value.length === 0 : value === ''
    )
    if (isEmpty) {
      setIsButtonActive(false)
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    const newParams = {
      ...searchParams,
      [name]: value
    }
    setSearchParams(newParams)
    
    // Check if all fields are empty
    const isEmpty = Object.values(newParams).every(value => 
      Array.isArray(value) ? value.length === 0 : value === ''
    )
    if (isEmpty) {
      setIsButtonActive(false)
    }
  }

  const handleTransactionTypeChange = (selectedOptions: any) => {
    let updatedTransactionTypes = selectedOptions.map((option: any) => option.value)

    if (updatedTransactionTypes.includes('both')) {
      updatedTransactionTypes = ['sale', 'lease']
    } else {
      updatedTransactionTypes = updatedTransactionTypes.filter((type: string) => type !== 'both')
    }

    setSearchParams(prevParams => ({
      ...prevParams,
      transactionType: updatedTransactionTypes
    }))
  }

  const handleSaveSearch = async () => {
    if (!isLoggedIn) {
      toast({
        title: "Login Required",
        description: "Please log in to save searches.",
      })
      return
    }

    const searchName = prompt("Enter a name for this search:")
    if (searchName) {
      try {
        const response = await axios.post('saved_items/save_search', {
          search_name: searchName,
          search_criteria: searchParams
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        toast({
          title: "Success",
          description: "Search saved successfully!",
        })
      } catch (error) {
        console.error('Error saving search:', error)
        toast({
          title: "Error",
          description: "An error occurred while saving the search. Please try again.",
          variant: "destructive",
        })
      }
    }
  }

  const handleViewSavedSearches = async () => {
    if (!isLoggedIn) {
      toast({
        title: "Login Required",
        description: "Please log in to view saved searches.",
      })
      return
    }

    try {
      const response = await axios.get('saved_items/get_saved_searches', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      setSavedSearches(response.data)
      setShowSavedSearches(true)
    } catch (error) {
      console.error('Error fetching saved searches:', error)
      toast({
        title: "Error",
        description: "An error occurred while fetching saved searches. Please try again.",
        variant: "destructive",
      })
    }
  }

  const handleApplySavedSearch = async (searchCriteria: SearchParams) => {
    setSearchParams(searchCriteria)
    setShowSavedSearches(false)
    if (onApplySavedSearch) {
      onApplySavedSearch(searchCriteria)
    } else {
      try {
        const response = await axios.get('search_controls/search', {
          params: { ...searchCriteria, all_results, page: 1 },
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        if (onSearchResults) {
          onSearchResults(response.data, searchCriteria)
        }
      } catch (error) {
        console.error('Error applying saved search:', error)
        toast({
          title: "Error",
          description: "An error occurred while applying the saved search. Please try again.",
          variant: "destructive",
        })
      }
    }
  }

  const handleDeleteSavedSearch = async (searchId: number) => {
    if (!isLoggedIn) {
      toast({
        title: "Login Required",
        description: "Please log in to delete saved searches.",
      })
      return
    }

    try {
      await axios.delete(`saved_items/delete_saved_search/${searchId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      setSavedSearches(prevSearches => prevSearches.filter(search => search.id !== searchId))
      toast({
        title: "Success",
        description: "Search deleted successfully!",
      })
    } catch (error) {
      console.error('Error deleting saved search:', error)
      toast({
        title: "Error",
        description: "An error occurred while deleting the saved search. Please try again.",
        variant: "destructive",
      })
    }
  }

  const handleReset = () => {
    setSearchParams({
      searchTerm: '',
      county: [],
      state: [],
      city: [],
      zipcode: [],
      transactionType: [],
      propertyType: [],
      MinSquareFootage: '',
      MaxSquareFootage: '',
      MinAcres: '',
      MaxAcres: '',
    })
    setIsButtonActive(false);
    if (onReset) {
      onReset()
    }
  }

  const handleViewFavorites = () => {
    if (!isLoggedIn) {
      toast({
        title: "Login Required",
        description: "Please log in to view favorites.",
      })
      return
    }

    const newState = !viewingFavorites
    setViewingFavorites(newState)
    if (onFavoritesToggle) {
      onFavoritesToggle(newState)
    }
  }

  const CustomValueContainer = ({ children, getValue, ...props }: any) => {
    const values = getValue()
    const valueCount = values.length

    return (
      <reactSelectComponents.ValueContainer {...props}>
        {children}
        {valueCount > 0 && (
          <div className="ms-1 text-sm text-muted-foreground">
            ({valueCount} selected)
          </div>
        )}
      </reactSelectComponents.ValueContainer>
    )
  }

  const CustomMenu = ({ children, ...props }: any) => {
    const values = props.getValue()

    return (
      <reactSelectComponents.Menu {...props}>
        {values.length > 0 && (
          <div>
            {values.map((value: any) => (
              <span key={value.value}>{value.label}</span>
            ))}
          </div>
        )}
        {children}
      </reactSelectComponents.Menu>
    )
  }

  // Add this function to filter county options based on selected states
  const getFilteredCountyOptions = useCallback(() => {
    if (searchParams.state.length === 0) {
      return countyOptions;
    }
    return countyOptions.filter(county => 
      searchParams.state.includes(county.state)
    );
  }, [searchParams.state, countyOptions]);

  const handleSearchClick = () => {
    setIsButtonActive(true);
    setShouldSearch(true);
  };

  return (
    <TooltipProvider>
      <Card className="w-full h-full mx-auto">
        <CardContent className="p-6 space-y-6">
          <div className="flex flex-wrap gap-4 justify-between">
            <Tooltip>
              <TooltipTrigger asChild>
                <span>
                  <Button variant="outline" onClick={handleSaveSearch} disabled={isBasicUser}>
                    Save Search
                  </Button>
                </span>
              </TooltipTrigger>
              {isBasicUser && (
                <TooltipContent>
                  <p>Upgrade for access</p>
                </TooltipContent>
              )}
            </Tooltip>
            <Tooltip>
              <TooltipTrigger asChild>
                <span>
                  <Button variant="outline" onClick={handleViewSavedSearches} disabled={isBasicUser}>
                    View Saved Searches
                  </Button>
                </span>
              </TooltipTrigger>
              {isBasicUser && (
                <TooltipContent>
                  <p>Upgrade for access</p>
                </TooltipContent>
              )}
            </Tooltip>
            {!hideViewFavorites && (
              <Button
                variant={viewingFavorites ? "secondary" : "outline"}
                onClick={handleViewFavorites}
              >
                {viewingFavorites ? 'Show All' : 'View Favorites'}
              </Button>
            )}
            <Button variant="outline" onClick={handleReset}>Reset</Button>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <Label htmlFor="state-select">State</Label>
              <Select
                isMulti
                name="state"
                options={stateOptions}
                value={stateOptions.filter(option => searchParams.state.includes(option.value))}
                onChange={(selectedOptions) => handleMultiSelectChange(selectedOptions, { name: 'state' })}
                placeholder="Select"
                components={{
                  ValueContainer: CustomValueContainer,
                  Menu: CustomMenu
                }}
              />
            </div>

            <div>
              <Label htmlFor="county-select">County</Label>
              <Select
                isMulti
                name="county"
                options={getFilteredCountyOptions()}
                value={countyOptions.filter(option => 
                  searchParams.county.includes(option.value)
                )}
                onChange={(selectedOptions) => handleMultiSelectChange(selectedOptions, { name: 'county' })}
                placeholder="Select"
                components={{
                  ValueContainer: CustomValueContainer,
                  Menu: CustomMenu
                }}
                styles={{
                  multiValue: (base) => ({
                    ...base,
                    marginRight: '4px'
                  }),
                  valueContainer: (base) => ({
                    ...base,
                    flexWrap: 'wrap'
                  })
                }}
              />
            </div>

            <div>
              <Label htmlFor="zipcode-select">Zipcode</Label>
              <AsyncSelect
                isMulti
                cacheOptions
                defaultOptions
                loadOptions={(inputValue) => loadOptions(inputValue, 'zipcode')}
                value={searchParams.zipcode.map(zip => ({ value: zip, label: zip }))}
                onChange={(selectedOptions) => handleMultiSelectChange(selectedOptions, { name: 'zipcode' })}
                placeholder="Enter Zipcode"
                components={{
                  ValueContainer: CustomValueContainer,
                  Menu: CustomMenu
                }}
                blurInputOnSelect={false}
                openMenuOnClick={false}
                isClearable={true}
              />
            </div>

            <div>
              <Label htmlFor="transaction-type-select">Transaction Type</Label>
              <Select
                isMulti
                name="transactionType"
                options={transactionOptions}
                value={transactionOptions.filter(option =>
                  searchParams.transactionType.includes(option.value) ||
                  (searchParams.transactionType.includes('sale') && searchParams.transactionType.includes('lease') && option.value === 'both')
                )}
                onChange={handleTransactionTypeChange}
                placeholder="Select"
                components={{
                  ValueContainer: CustomValueContainer,
                  Menu: CustomMenu
                }}
              />
            </div>

            <div>
              <Label htmlFor="property-type-select">Property Type</Label>
              <Select
                isMulti
                name="propertyType"
                options={propertyOptions}
                value={propertyOptions.filter(option => searchParams.propertyType.includes(option.value))}
                onChange={(selectedOptions) => handleMultiSelectChange(selectedOptions, { name: 'propertyType' })}
                placeholder="Select"
                components={{
                  ValueContainer: CustomValueContainer,
                  Menu: CustomMenu
                }}
              />
            </div>

            <div>
              <Label htmlFor="min-square-footage">Min Square Footage</Label>
              <Input
                id="min-square-footage"
                name="MinSquareFootage"
                type="number"
                value={searchParams.MinSquareFootage}
                onChange={handleInputChange}
                placeholder="Min SF"
              />
            </div>

            <div>
              <Label htmlFor="max-square-footage">Max Square Footage</Label>
              <Input
                id="max-square-footage"
                name="MaxSquareFootage"
                type="number"
                value={searchParams.MaxSquareFootage}
                onChange={handleInputChange}
                placeholder="Max SF"
              />
            </div>

            <div>
              <Label htmlFor="min-acres">Min Acres</Label>
              <Input
                id="min-acres"
                name="MinAcres"
                type="number"
                value={searchParams.MinAcres}
                onChange={handleInputChange}
                placeholder="Min AC"
              />
            </div>

            <div>
              <Label htmlFor="max-acres">Max Acres</Label>
              <Input
                id="max-acres"
                name="MaxAcres"
                type="number"
                value={searchParams.MaxAcres}
                onChange={handleInputChange}
                placeholder="Max AC"
              />
            </div>
          </div>

          <div className="flex items-center space-x-2">
            <Button 
              onClick={handleSearchClick} 
              className={`w-full md:w-auto ${isButtonActive ? styles.searchButtonActive : styles.searchButton}`}
              disabled={shouldSearch}
              size="lg"
            >
              {shouldSearch && (
                <svg
                  className="animate-spin -ml-1 mr-3 h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  />
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
              )}
              {shouldSearch ? 'Searching...' : 'Search'}
            </Button>
          </div>
        </CardContent>

        <Dialog open={showSavedSearches} onOpenChange={setShowSavedSearches}>
          <DialogContent className="bg-white dark:bg-gray-800">
            <DialogHeader>
              <DialogTitle>Saved Searches</DialogTitle>
            </DialogHeader>
            <ScrollArea className="h-[300px]">
              {savedSearches.map((search) => (
                <div key={search.id} className="flex items-center justify-between p-2 border-b">
                  <span>{search.search_name}</span>
                  <div className="space-x-2">
                    <Button size="sm" onClick={() => handleApplySavedSearch(search.search_criteria)}>
                      Apply
                    </Button>
                    <Button size="sm" variant="destructive" onClick={() => handleDeleteSavedSearch(search.id)}>
                      Delete
                    </Button>
                  </div>
                </div>
              ))}
            </ScrollArea>
          </DialogContent>
        </Dialog>
      </Card>
    </TooltipProvider>
  )
}

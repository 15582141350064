import styles from './DemoButton.module.css';
import { FaPlay } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const DemoButton = () => {
  return (
    <Link to="/contact-us" className={styles.buttonLink}>
      <button className={styles.button}>
        <FaPlay size={16} /> Schedule Demo
      </button>
    </Link>
  );
};

export default DemoButton;

import { useState, useEffect } from 'react';
import axios from '../utils/axios';
import { useAuth } from '../AuthContext';
import { Table, Button } from 'react-bootstrap';

const CompletedPurchasesTable = () => {
  const [purchases, setPurchases] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { token } = useAuth();

  useEffect(() => {
    const fetchPurchases = async () => {
      if (!token) {
        setError('You must be logged in to view purchases');
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get('/s3bucket/get_completed_purchases', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setPurchases(response.data);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch purchases');
        setLoading(false);
      }
    };

    fetchPurchases();
  }, [token]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      <h2>Completed Purchases</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Purchase ID</th>
            <th>Purchase Date</th>
            <th>File Types</th>
            <th>Query Details</th>
            <th>Download Links</th>
          </tr>
        </thead>
        <tbody>
          {purchases.map((purchase) => (
            <tr key={purchase.id}>
              <td>{purchase.id}</td>
              <td>{new Date(purchase.purchase_date).toLocaleDateString()}</td>
              <td>{purchase.file_types.join(', ')}</td>
              <td>
                {purchase.query && (
                  <div style={{ maxWidth: '300px', overflowX: 'auto' }}>
                    {Object.entries(purchase.query).map(([key, value]) => (
                      <div key={key} className="mb-1">
                        <strong>{key}:</strong>{' '}
                        {Array.isArray(value) 
                          ? value.join(', ')
                          : String(value)}
                      </div>
                    ))}
                  </div>
                )}
              </td>
              <td>
                {purchase.s3_url && Object.entries(purchase.s3_url).map(([fileType, url]) => (
                  <Button 
                    key={fileType} 
                    href={url} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    variant="primary"
                    size="sm"
                    className="mr-2 mb-2"
                  >
                    Download {fileType}
                  </Button>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default CompletedPurchasesTable;
import { useEffect, useState } from 'react';

const Robots = () => {
  const [robotsTxt, setRobotsTxt] = useState('');

  useEffect(() => {
    const domain = window.location.origin;
    
    // Create robots.txt content
    const content = `# www.robotstxt.org/
# Robots.txt file for ${domain}

User-agent: *
Allow: /
Allow: /map
Allow: /data-portal
Allow: /contact-us
Allow: /signup
Allow: /login
Allow: /forgot-password
Allow: /privacy

# Disallow admin and private routes
Disallow: /admin
Disallow: /profile
Disallow: /stripe
Disallow: /payment-success
Disallow: /payment-cancelled
Disallow: /reset-password
Disallow: /confirm-email
Disallow: /api
Disallow: /s3bucket
Disallow: /saved_items
Disallow: /stripe_subscription

# Crawl-delay
Crawl-delay: 10

# Sitemaps
Sitemap: ${domain}/sitemap.xml`;

    setRobotsTxt(content);
  }, []);

  return (
    <div>
      <pre style={{ 
        whiteSpace: 'pre-wrap', 
        wordWrap: 'break-word',
        backgroundColor: '#f5f5f5',
        padding: '1rem',
        borderRadius: '4px',
        fontSize: '14px',
        fontFamily: 'monospace'
      }}>
        {robotsTxt}
      </pre>
    </div>
  );
};

export default Robots; 
import React, { useMemo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Popup from '../../Popup/Popup.jsx';
import ClusterPopup from '../../Popup/ClusterPopup.jsx';
import styles from '../../Popup/Popup.module.css';

const MapPopup = ({ 
  popupInfo, 
  popupScreenPosition, 
  mapContainerRef,
  onClose,
  onNavigate,
  onListingUpdate 
}) => {
  const popupRef = useRef(null);

  const popupStyle = useMemo(() => {
    if (!popupInfo || !popupScreenPosition || !mapContainerRef.current) return null;

    const mapContainerBounds = mapContainerRef.current.getBoundingClientRect();
    const popupWidth = 400; // Fixed width from Popup.module.css
    const popupHeight = 500; // Approximate height, will be adjusted by max-height

    let x = popupScreenPosition.x;
    let y = popupScreenPosition.y;

    // Adjust x to prevent horizontal overflow
    if (x + popupWidth > mapContainerBounds.width) {
      x = x - popupWidth;
    }
    x = Math.max(10, x);

    // Adjust y position
    y = y - 10; // Position slightly below the point
    if (y + popupHeight > mapContainerBounds.height) {
      y = mapContainerBounds.height - popupHeight - 10;
    }
    y = Math.max(10, y);

    return {
      position: 'absolute',
      left: `${x}px`,
      top: `${y}px`,
      zIndex: 1000
    };
  }, [popupInfo, popupScreenPosition, mapContainerRef]);

  // Handle click outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  if (!popupInfo || !popupStyle) return null;

  return (
    <div 
      ref={popupRef}
      className={styles.popupWrapper}
      style={popupStyle}
      onClick={(e) => e.stopPropagation()}
    >
      {popupInfo.isCluster ? (
        <ClusterPopup
          clusterInfo={{
            listings: popupInfo.listings,
            currentIndex: popupInfo.currentIndex
          }}
          onClose={onClose}
          onNavigate={onNavigate}
          onListingUpdate={onListingUpdate}
        />
      ) : (
        <Popup
          listings={popupInfo.listings}
          currentIndex={popupInfo.currentIndex}
          onClose={onClose}
          onNavigate={onNavigate}
          onListingUpdate={onListingUpdate}
        />
      )}
    </div>
  );
};

MapPopup.propTypes = {
  popupInfo: PropTypes.shape({
    listings: PropTypes.array.isRequired,
    currentIndex: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
    latitude: PropTypes.number.isRequired,
    isCluster: PropTypes.bool
  }),
  popupScreenPosition: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }),
  mapContainerRef: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onNavigate: PropTypes.func.isRequired,
  onListingUpdate: PropTypes.func.isRequired,
};

export default MapPopup;
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import { AuthProvider, useAuth } from './AuthContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import LandingPage from './LandingPage/LandingPage.jsx';
import MapPage from './Map/map_page.jsx';
import DataportalContainer from './DataPortal/DataportalContainer.jsx';
import SignUp from './SignUp/SignUp.jsx';
import ContactUs from './ContactUs/ContactUs.jsx';
import ProfilePage from './ProfilePage/ProfilePage.jsx';
import Login from './Login/Login.jsx';
import Logout from './Logout/Logout.jsx';
import StripeSuccess from './stripe_component/PaymentSuccess.jsx';
import PaymentSuccess from './stripe_component/PaymentSuccess.jsx';
import PaymentCancelled from './stripe_component/PaymentCancelled.jsx';
import HealthCheck from './Healthcheck/Healthcheck.jsx';
import Sitemap from './sitemap/sitemap.jsx';
import Robots from './sitemap/robots.jsx';
import PrivacyPolicy from './sitemap/PrivacyPolicy.jsx';
import EmailConfirmation from './EmailConfirmation/EmailConfirmation.jsx';
import ResetPassword from './ResetPassword/ResetPassword.jsx';
import ForgotPassword from './ForgotPassword/ForgotPassword.jsx';
import './utils/axios';
import AdminPage from './Admin/AdminPage.jsx';
const queryClient = new QueryClient();

export const ProtectedRoute = ({ children }) => {
  const { isLoggedIn, loading } = useAuth();
  
  if (loading) {
    return <div>Loading...</div>;
  }
  
  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }
  
  return children;
};

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export const AdminRoute = ({ children }) => {
  const { isLoggedIn, isAdmin, loading } = useAuth();
  
  if (loading) {
    return <div>Loading...</div>;
  }
  
  if (!isLoggedIn || !isAdmin) {
    return <Navigate to="/" replace />;
  }
  
  return children;
};

AdminRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

createRoot(document.getElementById('root')).render(
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/map" element={<ProtectedRoute><MapPage /></ProtectedRoute>} />
            <Route path="/data-portal" element={<ProtectedRoute><DataportalContainer /></ProtectedRoute>} />
            <Route path="/profile" element={<ProtectedRoute><ProfilePage /></ProtectedRoute>} />
            <Route path="/stripe/success" element={<StripeSuccess />} />
            <Route path="/stripe/cancel" element={<Navigate to="/profile" replace />} />
            <Route path="/payment-success" element={<ProtectedRoute><PaymentSuccess /></ProtectedRoute>} />
            <Route path="/stripe/cancel" element={<ProtectedRoute><PaymentCancelled /></ProtectedRoute>} />
            <Route path="/confirm-email/:token" element={<EmailConfirmation />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/health" element={<HealthCheck />} />
            <Route path="/sitemap.xml" element={<Sitemap />} />
            <Route path="/robots.txt" element={<Robots />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/admin" element={
              <AdminRoute>
                <AdminPage />
              </AdminRoute>
            } />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </QueryClientProvider>
);

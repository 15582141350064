import { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { MAPBOX_ACCESS_TOKEN } from '../Map/constants';
import './HeroMap.css';
import Popup from '../Popup/Popup';

const HeroMap = () => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [popupData, setPopupData] = useState(null);
  const [currentPopupIndex, setCurrentPopupIndex] = useState(0);

  useEffect(() => {
    if (map.current) return;

    mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/light-v11',
      center: [-74.006, 40.7128],
      zoom: 11,
      interactive: false,
      attributionControl: false
    });

    // Define NJ and NYC metro areas with their bounds to ensure points are on land
    const metroAreas = [
      // Manhattan
      { center: [-73.9712, 40.7831], radius: 0.15, name: 'Manhattan' },
      // Brooklyn
      { center: [-73.9442, 40.6782], radius: 0.15, name: 'Brooklyn' },
      // Queens
      { center: [-73.7949, 40.7282], radius: 0.15, name: 'Queens' },
      // Jersey City
      { center: [-74.0776, 40.7282], radius: 0.1, name: 'Jersey City' },
      // Newark
      { center: [-74.1724, 40.7357], radius: 0.15, name: 'Newark' },
      // Hoboken
      { center: [-74.0323, 40.7439], radius: 0.05, name: 'Hoboken' },
      // Staten Island
      { center: [-74.1502, 40.5795], radius: 0.15, name: 'Staten Island' },
      // The Bronx
      { center: [-73.8648, 40.8448], radius: 0.15, name: 'The Bronx' },
      // Edison
      { center: [-74.4121, 40.5187], radius: 0.15, name: 'Edison' },
      // Elizabeth
      { center: [-74.2107, 40.6639], radius: 0.1, name: 'Elizabeth' },
      // Paterson
      { center: [-74.1715, 40.9168], radius: 0.1, name: 'Paterson' },
      // New Brunswick
      { center: [-74.4518, 40.4862], radius: 0.1, name: 'New Brunswick' }
    ];

    const generatePointsForMetro = async (map, metro, count) => {
      const points = [];
      let attempts = 0;
      const maxAttempts = count * 5;

      while (points.length < count && attempts < maxAttempts) {
        const angle = Math.random() * 2 * Math.PI;
        const r = Math.pow(Math.random(), 0.5) * metro.radius;
        const lng = metro.center[0] + r * Math.cos(angle);
        const lat = metro.center[1] + r * Math.sin(angle);

        // Check if point is on land
        const features = map.queryRenderedFeatures(
          map.project([lng, lat]),
          { layers: ['water'] }
        );

        // If no water features are found, this is a valid point on land
        if (features.length === 0) {
          const propertyTypes = ['Office', 'Retail', 'Industrial', 'Mixed-Use'];
          const type = propertyTypes[Math.floor(Math.random() * propertyTypes.length)];
          
          points.push({
            lng,
            lat,
            type,
            hash_id: `sample-${metro.name}-${Math.random().toString(36).substr(2, 9)}`,
            company_name: `Sample Company}`,
            address: `${Math.floor(Math.random() * 9999)} ${metro.name} Street`,
            property_type: type,
            transaction_type: Math.random() > 0.5 ? 'Sale' : 'Lease',
            max_sq_ft_available: `${Math.floor(Math.random() * 50000 + 10000)}`,
            min_sq_ft_available: `${Math.floor(Math.random() * 5000 + 1000)}`,
            max_ac_available: `${(Math.random() * 10 + 1).toFixed(2)}`,
            min_ac_available: `${(Math.random() * 1 + 0.1).toFixed(2)}`,
            broker: 'Sample Broker',
            email: 'sample@email.com',
            phone: '(555) 555-5555',
            brochure_link: '#',
            link: '#',
            isFavorited: false
          });
        }

        attempts++;
      }

      return points;
    };

    map.current.on('load', async () => {
      // Wait for the style to load completely
      if (!map.current.isStyleLoaded()) {
        await new Promise(resolve => {
          map.current.once('style.load', resolve);
        });
      }

      // Generate exactly 50 points for each metro area
      const POINTS_PER_AREA = 50;
      const allPoints = [];
      for (const metro of metroAreas) {
        const points = await generatePointsForMetro(map.current, metro, POINTS_PER_AREA);
        allPoints.push(...points);
      }

      // Add markers as a non-clustered GeoJSON source
      map.current.addSource('sample-locations', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: allPoints.map(loc => ({
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [loc.lng, loc.lat]
            },
            properties: {
              ...loc,
              type: loc.type
            }
          }))
        }
      });

      // Add unclustered points layer
      map.current.addLayer({
        id: 'location-points',
        type: 'circle',
        source: 'sample-locations',
        paint: {
          'circle-color': [
            'match',
            ['get', 'type'],
            'Office', '#F9507A',
            'Retail', '#4A90E2',
            'Industrial', '#50E3C2',
            'Mixed-Use', '#F5A623',
            '#F9507A'
          ],
          'circle-radius': 6,
          'circle-opacity': 0.8,
          'circle-stroke-width': 2,
          'circle-stroke-color': '#ffffff'
        }
      });

      // Add hover effect
      map.current.on('mouseenter', 'location-points', () => {
        map.current.getCanvas().style.cursor = 'pointer';
      });
      map.current.on('mouseleave', 'location-points', () => {
        map.current.getCanvas().style.cursor = '';
      });

      // Add click handler for points
      map.current.on('click', 'location-points', (e) => {
        const features = map.current.queryRenderedFeatures(e.point, {
          layers: ['location-points']
        });
        
        if (features.length > 0) {
          const clickedPoint = features[0].properties;
          setPopupData([clickedPoint]);
          setCurrentPopupIndex(0);
        }
      });

      // Add sign-up message overlay
      const signUpTextDiv = document.createElement('div');
      signUpTextDiv.className = 'map-overlay-text';
      signUpTextDiv.innerHTML = 'Free Sign-Up to see non-sample data';
      signUpTextDiv.style.position = 'absolute';
      signUpTextDiv.style.bottom = '20px';
      signUpTextDiv.style.left = '50%';
      signUpTextDiv.style.transform = 'translateX(-50%)';
      signUpTextDiv.style.backgroundColor = 'rgba(255, 255, 255, 0.9)';
      signUpTextDiv.style.padding = '8px 16px';
      signUpTextDiv.style.borderRadius = '4px';
      signUpTextDiv.style.fontSize = '14px';
      signUpTextDiv.style.fontWeight = '500';
      signUpTextDiv.style.color = '#333333';
      signUpTextDiv.style.zIndex = '1';
      signUpTextDiv.style.boxShadow = '0 2px 4px rgba(0,0,0,0.1)';
      mapContainer.current.appendChild(signUpTextDiv);
    });

    return () => {
      if (map.current) {
        map.current.remove();
      }
    };
  }, []);

  const handleClosePopup = () => {
    setPopupData(null);
  };

  const handlePopupNavigate = (direction) => {
    if (direction === 'next' && currentPopupIndex < popupData.length - 1) {
      setCurrentPopupIndex(prev => prev + 1);
    } else if (direction === 'prev' && currentPopupIndex > 0) {
      setCurrentPopupIndex(prev => prev - 1);
    }
  };

  return (
    <div ref={mapContainer} className="map-preview">
      {popupData && (
        <div className="popup-container">
          <Popup
            listings={popupData}
            currentIndex={currentPopupIndex}
            onClose={handleClosePopup}
            onNavigate={handlePopupNavigate}
            onListingUpdate={() => {}}
            disableFavorite={true}
          />
        </div>
      )}
    </div>
  );
};

export default HeroMap; 
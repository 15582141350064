import styles from './LoginButton.module.css';
import { FaSignInAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const LoginButton = () => {
  return (
    <Link to="/login" className={styles.buttonLink}>
      <button className={styles.button}>
        <FaSignInAlt /> Log In
      </button>
    </Link>
  );
};

export default LoginButton;

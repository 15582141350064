import './Hero.css';
import { motion } from 'framer-motion';
import SignUpButton from '../Buttons/SignUpButton';
import LoginButton from '../Buttons/LoginButton';
import DemoButton from '../Buttons/DemoButton';
import { useAuth } from '../AuthContext';
import HeroMap from './HeroMap';

const Hero = () => {
  const { isLoggedIn } = useAuth();

  return (
    <div className="home">
      <section className="hero">
        <div className="hero-content">
          <motion.div 
            className="hero-text"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <motion.div
              className="value-proposition"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.1 }}
            >
              <h1>Transform Your CRE Decision Making</h1>
              <h2>Access Comprehensive Market Intelligence Data</h2>
              <p className="hero-subtitle">
                Get instant access to 150,000+ verified commercial real estate listings, 
                market analytics, and data for actionable insights across all 50 states.
              </p>
            </motion.div>

            {!isLoggedIn && (
              <motion.div 
                className="cta-container"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                <div className="primary-cta">
                  <SignUpButton />
                  <p className="cta-subtitle">No credit card required</p>
                </div>
                <div className="secondary-cta">
                  <LoginButton />
                  <DemoButton />
                </div>
              </motion.div>
            )}
          </motion.div>

          <motion.div 
            className="hero-image"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <HeroMap />
          </motion.div>
        </div>
      </section>
    </div>
  );
};

export default Hero;

import { useCallback, useEffect } from 'react';
import { MOVE_END_DELAY } from '../constants';
import { MAP_LAYERS } from '../constants';

export const useMapEvents = ({
  map,
  mapLoadedRef,
  isSearchActiveRef,
  moveState,
  setPopupInfo,
  setPopupScreenPosition,
  onFetchListings,
  onFetchSavedListings,
  onClusterClick,
  onPointClick
}) => {
  const handleMoveEnd = useCallback(() => {
    if (moveState.moveEndTimer.current) {
      clearTimeout(moveState.moveEndTimer.current);
    }

    moveState.moveEndTimer.current = setTimeout(() => {
      const currentTime = Date.now();
      
      // Log skip state for debugging
      if (moveState.skipNextFetch) {
        console.log('Skipping automatic fetch due to programmatic movement');
        return;
      }

      if (
        currentTime - moveState.lastMoveTimestamp.current > MOVE_END_DELAY &&
        !isSearchActiveRef.current &&
        mapLoadedRef.current
      ) {
        console.log('Map move ended - Updating bounds before fetch');
        moveState.updateCurrentBounds();
        onFetchListings();
        onFetchSavedListings();
      }
    }, MOVE_END_DELAY);
    
    moveState.lastMoveTimestamp.current = Date.now();
  }, [mapLoadedRef, isSearchActiveRef, moveState, onFetchListings, onFetchSavedListings]);

  const updatePopupPosition = useCallback(() => {
    if (map.current && setPopupInfo && setPopupScreenPosition) {
      setPopupInfo(prev => {
        if (!prev) return null;
        const point = map.current.project([prev.longitude, prev.latitude]);
        setPopupScreenPosition(point);
        return prev;
      });
    }
  }, [map, setPopupInfo, setPopupScreenPosition]);

  useEffect(() => {
    const currentMap = map.current;
    if (!currentMap) return;

    const handleMapMove = () => {
      // Only update bounds if not a programmatic movement
      if (!moveState.skipNextFetch) {
        console.log('Map moved - Updating current bounds');
        moveState.updateCurrentBounds();
      } else {
        console.log('Skipping bounds update due to programmatic movement');
      }
    };

    currentMap.on('moveend', handleMoveEnd);
    currentMap.on('moveend', handleMapMove);
    currentMap.on('move', updatePopupPosition);

    const layers = [MAP_LAYERS.CLUSTERS, MAP_LAYERS.UNCLUSTERED_POINT, MAP_LAYERS.SAVED_LISTINGS];
    
    const handleMouseEnter = () => {
      currentMap.getCanvas().style.cursor = 'pointer';
    };

    const handleMouseLeave = () => {
      currentMap.getCanvas().style.cursor = '';
    };

    currentMap.on('click', MAP_LAYERS.CLUSTERS, (e) => {
      e.preventDefault();
      onClusterClick(e);
    });

    currentMap.on('click', MAP_LAYERS.UNCLUSTERED_POINT, (e) => {
      e.preventDefault();
      onPointClick(e);
    });

    currentMap.on('click', MAP_LAYERS.SAVED_LISTINGS, (e) => {
      e.preventDefault();
      onPointClick(e);
    });

    layers.forEach(layer => {
      currentMap.on('mouseenter', layer, handleMouseEnter);
      currentMap.on('mouseleave', layer, handleMouseLeave);
    });

    return () => {
      if (currentMap) {
        currentMap.off('moveend', handleMoveEnd);
        currentMap.off('moveend', handleMapMove);
        currentMap.off('move', updatePopupPosition);
        currentMap.off('click', MAP_LAYERS.CLUSTERS, onClusterClick);
        currentMap.off('click', MAP_LAYERS.UNCLUSTERED_POINT, onPointClick);
        currentMap.off('click', MAP_LAYERS.SAVED_LISTINGS, onPointClick);
      }
      layers.forEach(layer => {
        currentMap.off('mouseenter', layer, handleMouseEnter);
        currentMap.off('mouseleave', layer, handleMouseLeave);
      });
    };
  }, [map, handleMoveEnd, updatePopupPosition, moveState, onClusterClick, onPointClick]);

  return {
    handleMoveEnd,
    updatePopupPosition
  };
};
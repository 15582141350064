import { useCallback } from 'react';
import { mapService } from '../services/mapService';

export const useMapPopup = ({ map, setPopupInfo, setPopupScreenPosition }) => {
  const handleClosePopup = useCallback(() => {
    setPopupInfo(null);
    setPopupScreenPosition(null);
  }, [setPopupInfo, setPopupScreenPosition]);

  const handleNavigate = useCallback((direction) => {
    setPopupInfo((currentInfo) => {
      if (!currentInfo) return null;
      const newIndex = direction === 'next' 
        ? Math.min(currentInfo.currentIndex + 1, currentInfo.listings.length - 1)
        : Math.max(currentInfo.currentIndex - 1, 0);
      return { ...currentInfo, currentIndex: newIndex };
    });
  }, [setPopupInfo]);

  const handleClusterClick = useCallback((e) => {
    const features = map.current.queryRenderedFeatures(e.point, {
      layers: ['clusters']
    });

    if (!features.length) return;

    const clusterId = features[0].properties.cluster_id;
    const source = map.current.getSource('listings');
    const coordinates = features[0].geometry.coordinates.slice();

    // Get cluster properties
    const clusterProperties = features[0].properties;
    const pointCount = clusterProperties.point_count;

    // Get cluster leaves (actual points in the cluster)
    source.getClusterLeaves(clusterId, pointCount, 0, (err, leaves) => {
      if (err) return;

      // Process the leaves to get listing information
      const listings = leaves.map(leaf => {
        try {
          if (leaf.properties.listing) {
            return {
              ...JSON.parse(leaf.properties.listing),
              longitude: leaf.geometry.coordinates[0],
              latitude: leaf.geometry.coordinates[1]
            };
          } else {
            return {
              ...leaf.properties,
              longitude: leaf.geometry.coordinates[0],
              latitude: leaf.geometry.coordinates[1]
            };
          }
        } catch (error) {
          console.error('Error parsing cluster leaf:', error);
          return null;
        }
      }).filter(Boolean);

      if (!listings.length) return;

      // Set popup info for cluster
      setPopupInfo({
        listings,
        currentIndex: 0,
        longitude: coordinates[0],
        latitude: coordinates[1],
        isCluster: true
      });

      const point = map.current.project(coordinates);
      setPopupScreenPosition(point);
    });

    // Zoom to cluster
    source.getClusterExpansionZoom(clusterId, (err, zoom) => {
      if (err) return;
      map.current.easeTo({
        center: coordinates,
        zoom: zoom
      });
    });
  }, [map, setPopupInfo, setPopupScreenPosition]);

  const handlePointClick = useCallback((e) => {
    const features = map.current.queryRenderedFeatures(e.point, {
      layers: ['unclustered-point', 'saved-listings']
    });

    if (!features.length) {
      handleClosePopup();
      return;
    }

    const clickedPoint = features[0];
    const coordinates = clickedPoint.geometry.coordinates.slice();

    // Get all points at the clicked location
    const listings = features.map(feature => {
      try {
        if (feature.properties.listing) {
          return {
            ...JSON.parse(feature.properties.listing),
            longitude: feature.geometry.coordinates[0],
            latitude: feature.geometry.coordinates[1]
          };
        } else {
          return {
            ...feature.properties,
            longitude: feature.geometry.coordinates[0],
            latitude: feature.geometry.coordinates[1]
          };
        }
      } catch (error) {
        console.error('Error parsing listing:', error);
        return null;
      }
    }).filter(Boolean);

    if (!listings.length) {
      handleClosePopup();
      return;
    }

    // Ensure the popup appears over the correct location
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    setPopupInfo({
      listings,
      currentIndex: 0,
      longitude: coordinates[0],
      latitude: coordinates[1],
      isCluster: false
    });

    const point = map.current.project(coordinates);
    setPopupScreenPosition(point);
  }, [map, handleClosePopup, setPopupInfo, setPopupScreenPosition]);

  return {
    handleClosePopup,
    handleNavigate,
    handleClusterClick,
    handlePointClick
  };
};
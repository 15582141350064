import { useEffect, useState } from 'react';

const Sitemap = () => {
  const [sitemap, setSitemap] = useState('');
  const [loading, setLoading] = useState(true);

  // Map of frontend routes that should be in the sitemap
  const frontendRoutes = [
    {
      path: '/',
      priority: '1.0',
      changefreq: 'daily'
    },
    {
      path: '/map',
      priority: '0.9',
      changefreq: 'daily'
    },
    {
      path: '/data-portal',
      priority: '0.8',
      changefreq: 'daily'
    },
    {
      path: '/contact-us',
      priority: '0.7',
      changefreq: 'weekly'
    },
    {
      path: '/signup',
      priority: '0.7',
      changefreq: 'monthly'
    },
    {
      path: '/login',
      priority: '0.7',
      changefreq: 'monthly'
    },
    {
      path: '/forgot-password',
      priority: '0.6',
      changefreq: 'monthly'
    },
    {
      path: '/privacy',
      priority: '0.5',
      changefreq: 'monthly'
    }
  ];

  useEffect(() => {
    setLoading(true);
    
    // Create a new sitemap with frontend routes
    const domain = window.location.origin;
    const currentDate = new Date().toISOString();
    
    let xmlContent = '<?xml version="1.0" encoding="UTF-8"?>\n';
    xmlContent += '<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">\n';
    
    frontendRoutes.forEach(route => {
      xmlContent += '  <url>\n';
      xmlContent += `    <loc>${domain}${route.path}</loc>\n`;
      xmlContent += `    <lastmod>${currentDate}</lastmod>\n`;
      xmlContent += `    <changefreq>${route.changefreq}</changefreq>\n`;
      xmlContent += `    <priority>${route.priority}</priority>\n`;
      xmlContent += '  </url>\n';
    });
    
    xmlContent += '</urlset>';

    // Format the XML
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlContent, 'text/xml');
    const serializer = new XMLSerializer();
    const formattedXml = serializer.serializeToString(xmlDoc);
    
    setSitemap(formattedXml);
    setLoading(false);
  }, []);

  if (loading) {
    return <div>Loading sitemap...</div>;
  }

  return (
    <div>
      <pre style={{ 
        whiteSpace: 'pre-wrap', 
        wordWrap: 'break-word',
        backgroundColor: '#f5f5f5',
        padding: '1rem',
        borderRadius: '4px',
        fontSize: '14px'
      }}>
        {sitemap}
      </pre>
    </div>
  );
};

export default Sitemap;

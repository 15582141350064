import React from 'react';
import { Container } from 'react-bootstrap';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';

const PrivacyPolicy = () => {
  return (
    <>
      <Navbar />
      <Container className="py-5">
        <div style={{ maxWidth: '800px', margin: '0 auto' }}>
          <h1 className="mb-4">Privacy Policy</h1>
          <p className="text-muted">Last updated: {new Date().toLocaleDateString()}</p>

          <section className="mb-4">
            <h2>Introduction</h2>
            <p>
              At Canvassed AI ("we," "our," or "us"), we respect your privacy and are committed to protecting your personal information. 
              This privacy policy explains how we collect, use, disclose, and safeguard your information when you use our real estate data platform.
            </p>
          </section>

          <section className="mb-4">
            <h2>Information We Collect</h2>
            <h3>Personal Information</h3>
            <ul>
              <li>Name and contact information</li>
              <li>Account credentials</li>
              <li>Payment information</li>
              <li>Company information</li>
              <li>Usage data and preferences</li>
            </ul>

            <h3>Automatically Collected Information</h3>
            <ul>
              <li>IP address and device information</li>
              <li>Browser type and settings</li>
              <li>Usage patterns and preferences</li>
              <li>Cookies and similar tracking technologies</li>
            </ul>
          </section>

          <section className="mb-4">
            <h2>How We Use Your Information</h2>
            <ul>
              <li>Provide and maintain our services</li>
              <li>Process your transactions</li>
              <li>Send you service updates and notifications</li>
              <li>Improve our platform and user experience</li>
              <li>Comply with legal obligations</li>
              <li>Detect and prevent fraud</li>
            </ul>
          </section>

          <section className="mb-4">
            <h2>Information Sharing</h2>
            <p>We may share your information with:</p>
            <ul>
              <li>Service providers and business partners</li>
              <li>Legal authorities when required by law</li>
              <li>Professional advisors and investors</li>
              <li>Other users (only with your consent)</li>
            </ul>
          </section>

          <section className="mb-4">
            <h2>Data Security</h2>
            <p>
              We implement appropriate technical and organizational measures to protect your personal information. 
              However, no method of transmission over the internet is 100% secure, and we cannot guarantee absolute security.
            </p>
          </section>

          <section className="mb-4">
            <h2>Your Rights</h2>
            <p>You have the right to:</p>
            <ul>
              <li>Access your personal information</li>
              <li>Correct inaccurate data</li>
              <li>Request deletion of your data</li>
              <li>Object to processing of your data</li>
              <li>Withdraw consent</li>
              <li>Data portability</li>
            </ul>
          </section>

          <section className="mb-4">
            <h2>Cookies</h2>
            <p>
              We use cookies and similar tracking technologies to improve your browsing experience, 
              analyze site traffic, and understand where our visitors come from.
            </p>
          </section>

          <section className="mb-4">
            <h2>Children's Privacy</h2>
            <p>
              Our services are not intended for individuals under 18 years of age. 
              We do not knowingly collect personal information from children.
            </p>
          </section>

          <section className="mb-4">
            <h2>Changes to This Policy</h2>
            <p>
              We may update this privacy policy from time to time. We will notify you of any changes by posting the new policy on this page 
              and updating the "Last updated" date.
            </p>
          </section>

          <section className="mb-4">
            <h2>Contact Us</h2>
            <p>
              If you have any questions about this privacy policy or our practices, please contact us at:
            </p>
            <p>
              Email: info@canvassed.ai<br />
            </p>
          </section>
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default PrivacyPolicy; 
import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { FaMapMarkedAlt, FaPiggyBank } from 'react-icons/fa';
import styles from './features.module.css';
import { useInView } from '../Hooks/useInView';
import databaseIcon from '../assets/img/landing/database-illust.svg';
import mappingIcon from '../assets/img/landing/mapping-platform.svg';
import affordablePricingIcon from '../assets/img/landing/affordable.svg';

const Features = () => {
    const [nextSectionRef, isNextSectionInView] = useInView({ threshold: 0.1 });

    const features = [
        { name: 'Robust Listings Database', 
          description: 'Access a comprehensive database of listings, including detailed property information and contact details.', 
          icon: databaseIcon },
        { name: 'Detailed Mapping Platform', 
          description: 'Utilize our simple to use mapping system to visualize available properties to close your next deal.',
          icon: mappingIcon },
        { name: 'Affordable Pricing', 
          description: 'Sign up for free and get access to our competitive pricing options that meet your budget and needs no matter what size your business is.', 
          icon: affordablePricingIcon },
    ];

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.2,
                delayChildren: 0.3
            }
        }
    };

    return (
        <>
            <motion.section 
                id="features" 
                className={styles.features}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={containerVariants}
            >
                <Container fluid>
                    <Row className={styles.featureRow}>
                        <Col md={5} className={styles.headerColumn}>
                            <motion.div 
                                className={`${styles.headerWrapper} ${isNextSectionInView ? styles.hidden : ''}`}
                                variants={{
                                    hidden: { opacity: 0, y: -20 },
                                    visible: { 
                                        opacity: 1, 
                                        y: 0,
                                        transition: { duration: 0.6, ease: "easeOut" }
                                    }
                                }}
                            >
                                <h2>Features</h2>
                            </motion.div>
                        </Col>
                        <Col md={7} className={styles.cardsColumn}>
                            <div className={styles.cardsContainer}>
                                {features.map((feature, index) => (
                                    <FeatureCard key={index} feature={feature} index={index} />
                                ))}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </motion.section>
            <div ref={nextSectionRef} className={styles.observerTarget}></div>
        </>
    );
};

const FeatureCard = ({ feature, index }) => {
    const [cardRef] = useInView({ threshold: 0.1, triggerOnce: true });

    const cardVariants = {
        hidden: { 
            opacity: 0, 
            y: 20,
            scale: 0.95
        },
        visible: { 
            opacity: 1, 
            y: 0,
            scale: 1,
            transition: {
                duration: 0.5,
                delay: index * 0.1,
                ease: "easeOut"
            }
        }
    };

    const iconVariants = {
        hidden: { scale: 0.8, opacity: 0 },
        visible: { 
            scale: 1, 
            opacity: 1,
            transition: {
                duration: 0.3,
                delay: index * 0.1 + 0.2
            }
        },
        hover: { 
            scale: 1.1,
            transition: { duration: 0.2 }
        }
    };

    return (
        <motion.div
            ref={cardRef}
            className={styles.featureCard}
            variants={cardVariants}
            whileHover={{ 
                y: -4,
                transition: { duration: 0.2 }
            }}
        >
            <motion.div
                className={styles.featureIcon}
                variants={iconVariants}
                whileHover="hover"
            >
                {typeof feature.icon === 'string' ? 
                    <img src={feature.icon} alt={`${feature.name} icon`} className={styles.svgIcon} /> :
                    <feature.icon className={styles.svgIcon} />
                }
            </motion.div>
            <motion.div 
                className={styles.featureContent}
                variants={{
                    hidden: { opacity: 0, y: 10 },
                    visible: { 
                        opacity: 1, 
                        y: 0,
                        transition: { duration: 0.3, delay: index * 0.1 + 0.3 }
                    }
                }}
            >
                <h3>{feature.name}</h3>
                <p>{feature.description}</p>
            </motion.div>
        </motion.div>
    );
};

FeatureCard.propTypes = {
    feature: PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        icon: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.elementType
        ]).isRequired,
    }).isRequired,
    index: PropTypes.number.isRequired,
};

export default Features;

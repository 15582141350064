// Map configuration
export const DEFAULT_CENTER = {
    lng: -74.006,
    lat: 40.7128,
    zoom: 12
  };
  
  export const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1IjoiamVyZW15c2luZ2gyMSIsImEiOiJja3I2aWU2cXcydDBxMnFxdTd0YWl0dDlkIn0.yWYID-8FaI5HZ5DmV66PPw';
  
  export const NYC_BOUNDS = [-74.259087, 40.477398, -73.700172, 40.917576];
  
  // Timing constants
  export const MOVE_END_DELAY = 1000;
  
  // Layer styling
  export const CLUSTER_COLORS = {
    default: '#51bbd6',
    medium: '#f1f075',
    large: '#f28cb1'
  };
  
  export const CLUSTER_RADIUS = {
    small: 20,
    medium: 30,
    large: 40
  };
  
  export const POINT_STYLES = {
    regular: {
      color: '#11b4da',
      radius: 10,
      strokeWidth: 1,
      strokeColor: '#fff'
    },
    saved: {
      color: '#ff0000',
      radius: 10,
      strokeWidth: 2,
      strokeColor: '#ffffff'
    },
    search: {
      color: '#00FF00',
      radius: 12,
      strokeWidth: 2,
      strokeColor: '#FFFFFF'
    }
  };
  
  // Map layer IDs
  export const MAP_LAYERS = {
    LISTINGS: 'listings',
    CLUSTERS: 'clusters',
    UNCLUSTERED_POINT: 'unclustered-point',
    SAVED_LISTINGS: 'saved-listings',
    CLUSTER_COUNT: 'cluster-count',
    SEARCH_MARKER: 'search-marker'
  };
  
  // Source IDs
  export const MAP_SOURCES = {
    LISTINGS: 'listings',
    SAVED_LISTINGS: 'saved-listings',
    SEARCH_RESULT: 'search-result'
  };
  
  // Add these missing cluster-specific constants
  export const CLUSTER_STEPS = {
    FIRST_STEP: 100,
    SECOND_STEP: 750
  };
  
  export const CLUSTER_SIZES = {
    DEFAULT: 20,
    MEDIUM: 30,
    LARGE: 40
  };
  
  export const CLUSTER_TEXT = {
    FONT: ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    SIZE: 12
  };
import { memo } from 'react';
import { motion } from 'framer-motion';
import AnimatedBeamDemo from './beam';
import styles from './WhatIsCanvassed.module.css';

const WhatIsCanvassed = memo(() => {
  const features = [
    'Comprehensive property listings database',
    'Cost-effective data solutions',
    'Nationwide market coverage',
    'Verified and accurate data',
    'Easy-to-use interface',
    'Frequent data updates',
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5
      }
    }
  };

  return (
    <motion.section
      id="what-is-canvassed"
      className={styles.container}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      variants={containerVariants}
      aria-labelledby="what-is-title"
    >
      <div className={styles.contentWrapper}>
        <motion.div className={styles.textContent} variants={itemVariants}>
          <motion.h2 
            id="what-is-title" 
            className={styles.title}
            variants={itemVariants}
          >
            What is Canvassed AI?
          </motion.h2>
          <div className={styles.descriptionWrapper}>
            <motion.p className={styles.description} variants={itemVariants}>
              Canvassed AI is a leading commercial real estate data platform delivering comprehensive market intelligence across the United States. Our premier listings dataset provides detailed, accurate, and up-to-date information for thousands of commercial real estate properties nationwide.
            </motion.p>
            <motion.p className={styles.description} variants={itemVariants}>
              Using advanced machine learning algorithms and proprietary models, we aggregate data from individual brokerage houses to create the most comprehensive and accurate commercial real estate database. Our platform transforms how you discover and analyze potential deals, making market research more efficient and cost-effective.
            </motion.p>
            <motion.p className={styles.description} variants={itemVariants}>
              With Canvassed AI, you can streamline your workflow, automate research tasks, and unlock new possibilities in your daily operations. Experience the power of connected tools and AI-driven insights, all in one unified platform designed for modern real estate professionals.
            </motion.p>
            <motion.ul 
              className={styles.keyFeatures} 
              aria-label="Key Features"
              variants={containerVariants}
            >
              {features.map((feature, index) => (
                <motion.li 
                  key={index}
                  variants={itemVariants}
                  whileHover={{ scale: 1.02, transition: { duration: 0.2 } }}
                >
                  {feature}
                </motion.li>
              ))}
            </motion.ul>
          </div>
        </motion.div>
        <motion.div 
          className={styles.beamDemoWrapper} 
          variants={itemVariants}
          aria-hidden="true"
        >
          <AnimatedBeamDemo />
        </motion.div>
      </div>
    </motion.section>
  );
});

WhatIsCanvassed.displayName = 'WhatIsCanvassed';

export default WhatIsCanvassed;

import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar/Navbar';
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import axios from '../utils/axios';
import styles from './AdminPage.module.css';
import LoadingSpinner from '../components/ui/loading';

const AdminPage = () => {
  const { isAdmin, token } = useAuth();
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    first_name: '',
    last_name: '',
    company_name: '',
    phone: '',
    role_id: '',
    user_status: true,
    email_confirmed: false
  });
  const [sortConfig, setSortConfig] = useState({
    key: 'username',
    direction: 'asc'
  });
  const [loading, setLoading] = useState(false);

  // Redirect if not admin
  useEffect(() => {
    if (!isAdmin) {
      navigate('/');
    }
  }, [isAdmin, navigate]);

  useEffect(() => {
    if (isAdmin && token) {
      fetchUsers();
      fetchRoles();
    }
  }, [isAdmin, token]);

  const fetchRoles = async () => {
    try {
      const response = await axios.get('/admin/roles', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setRoles(response.data);
    } catch (error) {
      setError('Error fetching roles: ' + (error.response?.data?.error || error.message));
    }
  };

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/admin/users', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setUsers(response.data);
    } catch (error) {
      console.error('Full error details:', error);
      setError('Error fetching users: ' + (error.response?.data?.message || error.message));
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    
    try {
      // Validate required fields
      if (!formData.username || !formData.email || (!selectedUser && !formData.password) || !formData.role_id) {
        setError('Please fill in all required fields');
        return;
      }

      // Create payload (exclude password if empty during edit)
      const payload = { ...formData };
      if (selectedUser && !payload.password) {
        delete payload.password;
      }

      // Log the payload for debugging
      console.log('Sending payload:', payload);

      if (selectedUser) {
        const response = await axios.put(
          `admin/users/${selectedUser.user_id}`, 
          payload,
          {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }
        );
        setSuccess(response.data.message || 'User updated successfully');
      } else {
        const response = await axios.post(
          'admin/users', 
          payload,
          {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }
        );
        setSuccess(response.data.message || 'User created successfully');
      }
      
      await fetchUsers();
      resetForm();
    } catch (error) {
      console.error('Full error details:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
        stack: error.stack
      });
      
      // Set a more detailed error message
      const errorMessage = error.response?.data?.error || 
                          error.response?.data?.message || 
                          error.message || 
                          'Error saving user';
      setError(`Error saving user: ${errorMessage}`);
    }
  };

  const handleDelete = async (userId) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        await axios.delete(`admin/users/${userId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setSuccess('User deleted successfully');
        fetchUsers();
      } catch (error) {
        console.error('Error deleting user:', error);
        setError('Error deleting user: ' + (error.response?.data?.error || error.message));
      }
    }
  };

  const handleEdit = (user) => {
    setSelectedUser(user);
    setFormData({
      username: user.username,
      email: user.email,
      password: '',
      first_name: user.first_name || '',
      last_name: user.last_name || '',
      company_name: user.company_name || '',
      phone: user.phone || '',
      role_id: user.role_id || '',
      user_status: user.user_status,
      email_confirmed: user.email_confirmed
    });
  };

  const resetForm = () => {
    setSelectedUser(null);
    setFormData({
      username: '',
      email: '',
      password: '',
      first_name: '',
      last_name: '',
      company_name: '',
      phone: '',
      role_id: '',
      user_status: true,
      email_confirmed: false
    });
  };

  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const sortedUsers = React.useMemo(() => {
    const sortedArray = [...users];
    sortedArray.sort((a, b) => {
      let aValue = a[sortConfig.key];
      let bValue = b[sortConfig.key];

      // Special handling for name column
      if (sortConfig.key === 'name') {
        aValue = `${a.first_name || ''} ${a.last_name || ''}`.trim();
        bValue = `${b.first_name || ''} ${b.last_name || ''}`.trim();
      }

      if (sortConfig.key === 'last_seen') {
        aValue = a.last_seen ? new Date(a.last_seen).getTime() : -Infinity;
        bValue = b.last_seen ? new Date(b.last_seen).getTime() : -Infinity;
      }

      if (aValue === null) return 1;
      if (bValue === null) return -1;
      
      if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    });
    return sortedArray;
  }, [users, sortConfig]);

  if (!isAdmin) {
    return null; // or a loading spinner
  }

  return (
    <>
      <Navbar />
      <div className={styles['admin-container']}>
        {loading && <LoadingSpinner overlay={true} />}
        {error && (
          <div className={`${styles.alert} ${styles['alert-error']}`}>
            {error}
          </div>
        )}
        {success && (
          <div className={`${styles.alert} ${styles['alert-success']}`}>
            {success}
          </div>
        )}

        <form onSubmit={handleSubmit} className={styles['admin-form']}>
          <h2 className={styles['form-title']}>
            {selectedUser ? 'Edit User' : 'Create New User'}
          </h2>
          
          <div className={styles['form-grid']}>
            <input
              type="text"
              name="username"
              placeholder="Username"
              value={formData.username}
              onChange={handleInputChange}
              className={styles['form-input']}
              required
            />
            
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleInputChange}
              className={styles['form-input']}
              required
            />
            
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleInputChange}
              className={styles['form-input']}
              required={!selectedUser}
            />
            
            <input
              type="text"
              name="first_name"
              placeholder="First Name"
              value={formData.first_name}
              onChange={handleInputChange}
              className={styles['form-input']}
            />
            
            <input
              type="text"
              name="last_name"
              placeholder="Last Name"
              value={formData.last_name}
              onChange={handleInputChange}
              className={styles['form-input']}
            />
            
            <input
              type="text"
              name="company_name"
              placeholder="Company Name"
              value={formData.company_name}
              onChange={handleInputChange}
              className={styles['form-input']}
            />
            
            <input
              type="text"
              name="phone"
              placeholder="Phone"
              value={formData.phone}
              onChange={handleInputChange}
              className={styles['form-input']}
            />
            
            <select
              name="role_id"
              value={formData.role_id}
              onChange={handleInputChange}
              className={styles['form-select']}
              required
            >
              <option value="">Select Role</option>
              {roles.map(role => (
                <option key={role.id} value={role.id}>
                  {role.name}
                </option>
              ))}
            </select>

            <div className={styles['checkbox-group']}>
              <label className={styles['checkbox-label']}>
                <input
                  type="checkbox"
                  name="user_status"
                  checked={formData.user_status}
                  onChange={(e) => handleInputChange({
                    target: { name: 'user_status', value: e.target.checked }
                  })}
                />
                Active User
              </label>
              
              <label className={styles['checkbox-label']}>
                <input
                  type="checkbox"
                  name="email_confirmed"
                  checked={formData.email_confirmed}
                  onChange={(e) => handleInputChange({
                    target: { name: 'email_confirmed', value: e.target.checked }
                  })}
                />
                Email Confirmed
              </label>
            </div>
            
            <div className={styles['form-buttons']}>
              <button 
                type="submit" 
                className={`${styles.btn} ${styles['btn-primary']}`}
              >
                {selectedUser ? 'Update User' : 'Create User'}
              </button>
              {selectedUser && (
                <button
                  type="button"
                  onClick={resetForm}
                  className={`${styles.btn} ${styles['btn-secondary']}`}
                >
                  Cancel
                </button>
              )}
            </div>
          </div>
        </form>

        <div className="table-container">
          <table className={styles['admin-table']}>
            <thead>
              <tr>
                <th onClick={() => handleSort('username')} className={styles.sortable}>
                  Username {sortConfig.key === 'username' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                </th>
                <th onClick={() => handleSort('email')} className={styles.sortable}>
                  Email {sortConfig.key === 'email' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                </th>
                <th onClick={() => handleSort('name')} className={styles.sortable}>
                  Name {sortConfig.key === 'name' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                </th>
                <th onClick={() => handleSort('company_name')} className={styles.sortable}>
                  Company {sortConfig.key === 'company_name' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                </th>
                <th onClick={() => handleSort('role_name')} className={styles.sortable}>
                  Role {sortConfig.key === 'role_name' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                </th>
                <th onClick={() => handleSort('user_status')} className={styles.sortable}>
                  Status {sortConfig.key === 'user_status' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                </th>
                <th onClick={() => handleSort('last_seen')} className={styles.sortable}>
                  Last Login {sortConfig.key === 'last_seen' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {sortedUsers.map((user) => (
                <tr key={user.user_id}>
                  <td>{user.username}</td>
                  <td>{user.email}</td>
                  <td>{`${user.first_name || ''} ${user.last_name || ''}`}</td>
                  <td>{user.company_name}</td>
                  <td>{user.role_name}</td>
                  <td>
                    <span className={`${styles['status-badge']} ${user.user_status ? styles['status-active'] : styles['status-inactive']}`}>
                      {user.user_status ? 'Active' : 'Inactive'}
                    </span>
                  </td>
                  <td>{user.last_seen ? new Date(user.last_seen).toLocaleString() : 'Never'}</td>
                  <td>
                    <button
                      onClick={() => handleEdit(user)}
                      className={`${styles.btn} ${styles['btn-edit']}`}
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDelete(user.user_id)}
                      className={`${styles.btn} ${styles['btn-delete']}`}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default AdminPage;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import mapboxgl from 'mapbox-gl';
import { NYC_BOUNDS, DEFAULT_CENTER } from '../constants';
import { mapService } from '../services/mapService';

// Create a custom home button control
class HomeButtonControl {
  onAdd(map) {
    this._map = map;
    this._container = document.createElement('div');
    this._container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
    
    const button = document.createElement('button');
    button.className = 'mapboxgl-ctrl-icon';
    button.type = 'button';
    button.style.display = 'flex';
    button.style.alignItems = 'center';
    button.style.justifyContent = 'center';
    button.style.padding = '5px';
    button.setAttribute('aria-label', 'Reset map position');
    
    // Add home icon using SVG
    button.innerHTML = `
      <svg viewBox="0 0 24 24" width="20" height="20">
        <path d="M12 2L1 12h3v9h6v-6h4v6h6v-9h3L12 2z" fill="currentColor"/>
      </svg>
    `;
    
    button.onclick = () => {
      map.flyTo({
        center: [DEFAULT_CENTER.lng, DEFAULT_CENTER.lat],
        zoom: DEFAULT_CENTER.zoom,
        duration: 1500
      });
    };
    
    this._container.appendChild(button);
    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }
}

const MapControls = ({ map, center }) => {
  useEffect(() => {
    const currentMap = map.current;
    if (!currentMap) return;

    // Add the geocoder control
    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      marker: false,
      placeholder: 'Search for a location',
      types: 'address,place,region,country',
      // bbox: NYC_BOUNDS,
      proximity: {
        longitude: center.lng,
        latitude: center.lat
      }
    });

    currentMap.addControl(geocoder, 'top-right');
    currentMap.addControl(new mapboxgl.NavigationControl(), 'top-right');
    currentMap.addControl(new HomeButtonControl(), 'top-right');

    // Handle the result
    geocoder.on('result', (e) => {
      const coordinates = e.result.center;
      
      // Update the search marker
      mapService.updateSearchMarker(currentMap, coordinates);

      // Fly to the location
      currentMap.flyTo({
        center: coordinates,
        zoom: 10
      });
    });

    // Clear the marker when the search is cleared
    geocoder.on('clear', () => {
      mapService.updateSearchMarker(currentMap, null);
    });

    // Cleanup
    return () => {
      if (currentMap) {
        const geocoderControl = currentMap._controls.find(
          control => control instanceof MapboxGeocoder
        );
        if (geocoderControl) {
          currentMap.removeControl(geocoderControl);
        }
        
        const navigationControl = currentMap._controls.find(
          control => control instanceof mapboxgl.NavigationControl
        );
        if (navigationControl) {
          currentMap.removeControl(navigationControl);
        }

        const homeControl = currentMap._controls.find(
          control => control instanceof HomeButtonControl
        );
        if (homeControl) {
          currentMap.removeControl(homeControl);
        }
      }
    };
  }, [map, center]);

  return null; // This is a behavior-only component
};

MapControls.propTypes = {
  map: PropTypes.shape({
    current: PropTypes.object
  }).isRequired,
  center: PropTypes.shape({
    lng: PropTypes.number.isRequired,
    lat: PropTypes.number.isRequired
  }).isRequired
};

export default MapControls;
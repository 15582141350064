import mapboxgl from 'mapbox-gl';
import { MAP_SOURCES, MAP_LAYERS, POINT_STYLES } from '../constants';

export const mapService = {
  initializeMap(container, center) {
    const map = new mapboxgl.Map({
      container,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [center.lng, center.lat],
      zoom: center.zoom,
    });

    return map;
  },

  initializeSources(map) {
    // Initialize listings source with clustering
    map.addSource(MAP_SOURCES.LISTINGS, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
      cluster: true,
      clusterMaxZoom: 14,
      clusterRadius: 50,
    });

    // Add layers for the listings source
    map.addLayer({
      id: MAP_LAYERS.CLUSTERS,
      type: 'circle',
      source: MAP_SOURCES.LISTINGS,
      filter: ['has', 'point_count'],
      paint: {
        'circle-color': [
          'step',
          ['get', 'point_count'],
          '#51bbd6',
          100,
          '#f1f075',
          750,
          '#f28cb1'
        ],
        'circle-radius': [
          'step',
          ['get', 'point_count'],
          20,
          100,
          30,
          750,
          40
        ]
      }
    });

    map.addLayer({
      id: MAP_LAYERS.UNCLUSTERED_POINT,
      type: 'circle',
      source: MAP_SOURCES.LISTINGS,
      filter: ['!', ['has', 'point_count']],
      paint: {
        'circle-color': '#51bbd6',  // Light blue for regular points
        'circle-radius': 8,
        'circle-stroke-width': 1,
        'circle-stroke-color': '#ffffff'
      }
    });

    // Initialize saved listings source (without clustering)
    map.addSource(MAP_SOURCES.SAVED_LISTINGS, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      }
    });

    // Add layer for saved listings with more prominent styling
    map.addLayer({
      id: MAP_LAYERS.SAVED_LISTINGS,
      type: 'circle',
      source: MAP_SOURCES.SAVED_LISTINGS,
      paint: {
        'circle-color': '#ff3b30',  // Bright red for saved listings
        'circle-radius': 12,        // Larger radius
        'circle-stroke-width': 2,
        'circle-stroke-color': '#ffffff',
        'circle-opacity': 0.9
      },
      layout: {
        'visibility': 'visible'  // Always start with saved listings visible
      }
    });

    // Add cluster count layer
    map.addLayer({
      id: MAP_LAYERS.CLUSTER_COUNT,
      type: 'symbol',
      source: MAP_SOURCES.LISTINGS,
      filter: ['has', 'point_count'],
      layout: {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12
      }
    });

    // Set initial layer visibility
    const regularLayers = [
      MAP_LAYERS.CLUSTERS,
      MAP_LAYERS.CLUSTER_COUNT,
      MAP_LAYERS.UNCLUSTERED_POINT
    ];
    
    regularLayers.forEach(layer => {
      map.setLayoutProperty(layer, 'visibility', 'visible');
    });
  },

  updateSearchMarker(map, coordinates) {
    if (!map.getSource(MAP_SOURCES.SEARCH_RESULT)) return;

    const features = coordinates ? [{
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates
      },
      properties: {}
    }] : [];

    map.getSource(MAP_SOURCES.SEARCH_RESULT).setData({
      type: 'FeatureCollection',
      features
    });
  },

  convertListingsToGeoJSON(listings, savedHashIds = new Set()) {
    return {
      type: 'FeatureCollection',
      features: listings
        .filter(listing => {
          const hashId = listing['Hash ID'] || listing.hash_id;
          if (savedHashIds.has(hashId)) return false;

          const lon = listing.Longitude || listing.longitude || listing.Long || listing.long;
          const lat = listing.Latitude || listing.latitude || listing.Lat || listing.lat;
          return lon && lat && !isNaN(parseFloat(lon)) && !isNaN(parseFloat(lat));
        })
        .map(listing => this.createFeatureFromListing(listing))
    };
  },

  createFeatureFromListing(listing) {
    const lon = listing.Longitude || listing.longitude || listing.Long || listing.long;
    const lat = listing.Latitude || listing.latitude || listing.Lat || listing.lat;
    
    return {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [parseFloat(lon), parseFloat(lat)]
      },
      properties: {
        hash_id: listing['Hash ID'] || listing.hash_id || '',
        company_name: listing['Company Name'] || listing.company_name || 'N/A',
        address: listing.Address || listing.address || 'N/A',
        property_type: listing['Property Type'] || listing.property_type || 'N/A',
        transaction_type: listing['Transaction Type'] || listing.transaction_type || 'N/A',
        min_sq_ft_available: listing['Min Sq Ft Available'] || listing.min_sq_ft_available || 'N/A',
        max_sq_ft_available: listing['Max Sq Ft Available'] || listing.max_sq_ft_available || 'N/A',
        broker: listing['Broker'] || listing.broker || 'N/A',
        email: listing['Email'] || listing.email || 'N/A',
        phone: listing['Phone'] || listing.phone || 'N/A',
        brochure_link: listing['Brochure Link'] || listing.brochure_link || 'N/A',
        max_ac_available: listing['Max Acres Available'] || listing.max_ac_available || 'N/A',
        min_ac_available: listing['Min Acres Available'] || listing.min_ac_available || 'N/A',
        link: listing['Link'] || listing.link || 'N/A',
        isSaved: listing.isSaved || false
      }
    };
  },

  toggleLayerVisibility(map, showingFavorites) {
    const regularLayers = [
      MAP_LAYERS.CLUSTERS,
      MAP_LAYERS.CLUSTER_COUNT,
      MAP_LAYERS.UNCLUSTERED_POINT
    ];

    // When showing favorites, hide regular listings
    const regularVisibility = showingFavorites ? 'none' : 'visible';
    regularLayers.forEach(layer => {
      if (map.getLayer(layer)) {
        map.setLayoutProperty(layer, 'visibility', regularVisibility);
      }
    });
    
    // Always show saved listings layer
    if (map.getLayer(MAP_LAYERS.SAVED_LISTINGS)) {
      map.setLayoutProperty(MAP_LAYERS.SAVED_LISTINGS, 'visibility', 'visible');
    }
  },

  fitMapToSearchResults(map, listings, searchParams) {
    if (!listings?.length) return;

    const hasGeographicSearch = ['county', 'state', 'city', 'zipcode'].some(param => 
      searchParams?.[param]?.length > 0
    );

    if (hasGeographicSearch) {
      const bounds = new mapboxgl.LngLatBounds();
      
      listings.forEach(listing => {
        const lon = listing.Longitude || listing.longitude || listing.Long || listing.long;
        const lat = listing.Latitude || listing.latitude || listing.Lat || listing.lat;
        if (lon && lat) {
          bounds.extend([parseFloat(lon), parseFloat(lat)]);
        }
      });

      // Calculate responsive padding based on screen size
      const isMobile = window.innerWidth <= 768;
      const padding = {
        top: isMobile ? 60 : 200,
        bottom: isMobile ? 300 : 200, // Extra padding for bottom on mobile to account for search panel
        left: isMobile ? 20 : 200,
        right: isMobile ? 20 : 200
      };

      map.fitBounds(bounds, {
        padding,
        maxZoom: isMobile ? 10 : 12, // Slightly lower max zoom on mobile for better context
        duration: 1000
      });
    }
  },

  updateListings(map, listings, savedListings = []) {
    if (!map.getSource(MAP_SOURCES.LISTINGS)) return;

    // Create a Set of saved listing hash IDs for quick lookup
    const savedHashIds = new Set(savedListings.map(saved => saved.hash_id));

    // Convert regular listings to GeoJSON format (excluding saved ones)
    const features = listings
      .filter(listing => {
        const hashId = listing['Hash ID'] || listing.hash_id;
        if (savedHashIds.has(hashId)) return false;

        const lon = listing.Longitude || listing.longitude || listing.Long || listing.long;
        const lat = listing.Latitude || listing.latitude || listing.Lat || listing.lat;
        return lon && lat && !isNaN(parseFloat(lon)) && !isNaN(parseFloat(lat));
      })
      .map(listing => {
        const lon = listing.Longitude || listing.longitude || listing.Long || listing.long;
        const lat = listing.Latitude || listing.latitude || listing.Lat || listing.lat;
        
        const listingData = {
          hash_id: listing['Hash ID'] || listing.hash_id,
          company_name: listing['Company Name'] || listing.company_name || 'N/A',
          address: listing['Address'] || listing.address || 'N/A',
          property_type: listing['Property Type'] || listing.property_type || 'N/A',
          transaction_type: listing['Transaction Type'] || listing.transaction_type || 'N/A',
          min_sq_ft_available: listing['Min Sq Ft Available'] || listing.min_sq_ft_available || 'N/A',
          max_sq_ft_available: listing['Max Sq Ft Available'] || listing.max_sq_ft_available || 'N/A',
          max_ac_available: listing['Max Acres Available'] || listing.max_ac_available || 'N/A',
          min_ac_available: listing['Min Acres Available'] || listing.min_ac_available || 'N/A',
          broker: listing['Broker'] || listing.broker || 'N/A',
          email: listing['Email'] || listing.email || 'N/A',
          phone: listing['Phone'] || listing.phone || 'N/A',
          brochure_link: listing['Brochure Link'] || listing.brochure_link || 'N/A',
          link: listing['Link'] || listing.link || 'N/A',
          isFavorited: false
        };

        return {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [parseFloat(lon), parseFloat(lat)]
          },
          properties: {
            ...listingData,
            cluster: false,
            listing: JSON.stringify(listingData)
          }
        };
      });

    // Convert saved listings to GeoJSON
    const savedFeatures = savedListings.map(listing => {
      const listingData = {
        hash_id: listing.hash_id,
        company_name: listing.company_name || 'N/A',
        address: listing.address || 'N/A',
        property_type: listing.property_type || 'N/A',
        transaction_type: listing.transaction_type || 'N/A',
        min_sq_ft_available: listing.min_sq_ft_available || 'N/A',
        max_sq_ft_available: listing.max_sq_ft_available || 'N/A',
        max_ac_available: listing.max_ac_available || 'N/A',
        min_ac_available: listing.min_ac_available || 'N/A',
        broker: listing.broker || 'N/A',
        email: listing.email || 'N/A',
        phone: listing.phone || 'N/A',
        brochure_link: listing.brochure_link || 'N/A',
        link: listing.link || 'N/A',
        isFavorited: true
      };

      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [parseFloat(listing.longitude), parseFloat(listing.latitude)]
        },
        properties: {
          ...listingData,
          listing: JSON.stringify(listingData)
        }
      };
    });

    // Update both sources
    map.getSource(MAP_SOURCES.LISTINGS).setData({
      type: 'FeatureCollection',
      features
    });

    map.getSource(MAP_SOURCES.SAVED_LISTINGS).setData({
      type: 'FeatureCollection',
      features: savedFeatures
    });

    // Ensure both layers are visible by default
    const regularLayers = [
      MAP_LAYERS.CLUSTERS,
      MAP_LAYERS.CLUSTER_COUNT,
      MAP_LAYERS.UNCLUSTERED_POINT
    ];
    
    regularLayers.forEach(layer => {
      if (map.getLayer(layer)) {
        map.setLayoutProperty(layer, 'visibility', 'visible');
      }
    });

    // Always show saved listings layer
    if (map.getLayer(MAP_LAYERS.SAVED_LISTINGS)) {
      map.setLayoutProperty(MAP_LAYERS.SAVED_LISTINGS, 'visibility', 'visible');
    }

    console.log(`Updated map with ${features.length} regular listings and ${savedFeatures.length} saved listings`);
  },

  updateSingleListing(map, listing, isFavorited) {
    if (!map.getSource(MAP_SOURCES.LISTINGS) || !map.getSource(MAP_SOURCES.SAVED_LISTINGS)) return;

    // Get current data from both sources
    const regularSource = map.getSource(MAP_SOURCES.LISTINGS);
    const savedSource = map.getSource(MAP_SOURCES.SAVED_LISTINGS);
    const regularData = regularSource._data || { type: 'FeatureCollection', features: [] };
    const savedData = savedSource._data || { type: 'FeatureCollection', features: [] };

    if (isFavorited) {
      // Remove from regular listings and add to saved listings
      const updatedRegularFeatures = regularData.features.filter(
        feature => feature.properties.hash_id !== listing.hash_id
      );

      // Create the saved listing feature
      const savedFeature = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [parseFloat(listing.longitude), parseFloat(listing.latitude)]
        },
        properties: {
          hash_id: listing.hash_id,
          company_name: listing.company_name || 'N/A',
          address: listing.address || 'N/A',
          property_type: listing.property_type || 'N/A',
          transaction_type: listing.transaction_type || 'N/A',
          min_sq_ft_available: listing.min_sq_ft_available || 'N/A',
          max_sq_ft_available: listing.max_sq_ft_available || 'N/A',
          max_ac_available: listing.max_ac_available || 'N/A',
          min_ac_available: listing.min_ac_available || 'N/A',
          broker: listing.broker || 'N/A',
          email: listing.email || 'N/A',
          phone: listing.phone || 'N/A',
          brochure_link: listing.brochure_link || 'N/A',
          link: listing.link || 'N/A',
          isFavorited: true,
          listing: JSON.stringify({
            ...listing,
            isFavorited: true
          })
        }
      };

      // Update both sources
      regularSource.setData({
        type: 'FeatureCollection',
        features: updatedRegularFeatures
      });

      savedSource.setData({
        type: 'FeatureCollection',
        features: [...savedData.features, savedFeature]
      });
    } else {
      // Remove from saved listings and add back to regular listings
      const updatedSavedFeatures = savedData.features.filter(
        feature => feature.properties.hash_id !== listing.hash_id
      );

      // Create the regular listing feature
      const regularFeature = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [parseFloat(listing.longitude), parseFloat(listing.latitude)]
        },
        properties: {
          hash_id: listing.hash_id,
          company_name: listing.company_name || 'N/A',
          address: listing.address || 'N/A',
          property_type: listing.property_type || 'N/A',
          transaction_type: listing.transaction_type || 'N/A',
          min_sq_ft_available: listing.min_sq_ft_available || 'N/A',
          max_sq_ft_available: listing.max_sq_ft_available || 'N/A',
          max_ac_available: listing.max_ac_available || 'N/A',
          min_ac_available: listing.min_ac_available || 'N/A',
          broker: listing.broker || 'N/A',
          email: listing.email || 'N/A',
          phone: listing.phone || 'N/A',
          brochure_link: listing.brochure_link || 'N/A',
          link: listing.link || 'N/A',
          isFavorited: false,
          cluster: false,
          listing: JSON.stringify({
            ...listing,
            isFavorited: false
          })
        }
      };

      // Update both sources
      savedSource.setData({
        type: 'FeatureCollection',
        features: updatedSavedFeatures
      });

      regularSource.setData({
        type: 'FeatureCollection',
        features: [...regularData.features, regularFeature]
      });
    }

    console.log(`Updated listing ${listing.hash_id} favorite status to ${isFavorited}`);
  },

  getClusterData(map, clusterId) {
    return new Promise((resolve, reject) => {
      map.getSource(MAP_SOURCES.LISTINGS).getClusterLeaves(
        clusterId,
        100,  // Limit to 100 points per cluster
        0,    // Start at the beginning
        (err, features) => {
          if (err) {
            console.error('Error getting cluster leaves:', err);
            reject(err);
            return;
          }

          // Extract and parse the listing data from each feature
          const listings = features.map(feature => {
            try {
              // First try to get the data from the top-level properties
              if (feature.properties.hash_id) {
                return {
                  ...feature.properties,
                  longitude: feature.geometry.coordinates[0],
                  latitude: feature.geometry.coordinates[1],
                  isFavorited: feature.properties.isFavorited || false
                };
              }
              
              // If that fails, try to parse the stringified listing data
              const listingData = JSON.parse(feature.properties.listing);
              return {
                ...listingData,
                longitude: feature.geometry.coordinates[0],
                latitude: feature.geometry.coordinates[1],
                isFavorited: feature.properties.isFavorited || false
              };
            } catch (error) {
              console.error('Error parsing listing data:', error);
              return null;
            }
          }).filter(Boolean);

          resolve(listings);
        }
      );
    });
  }
};
import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from '../Hooks/useInView';
import styles from './CountUp.module.css';

const CountUp = ({ end, duration = 2000, label, formatNumber = true, delay = 0 }) => {
  const [count, setCount] = useState(0);
  const controls = useAnimation();
  const [ref, isInView] = useInView({ threshold: 0.3, triggerOnce: true });

  const easeOutQuad = t => t * (2 - t);

  const animate = useCallback((timestamp, startTime, animationFrame) => {
    if (!startTime) {
      return requestAnimationFrame((newTimestamp) => 
        animate(newTimestamp, newTimestamp, animationFrame)
      );
    }

    const progress = timestamp - startTime;
    const percentage = Math.min(progress / duration, 1);
    const easedPercentage = easeOutQuad(percentage);
    const currentCount = Math.floor(easedPercentage * end);

    setCount(currentCount);

    if (percentage < 1) {
      return requestAnimationFrame((newTimestamp) => 
        animate(newTimestamp, startTime, animationFrame)
      );
    }
    return null;
  }, [duration, end]);

  useEffect(() => {
    let animationFrame;
    
    if (isInView) {
      setTimeout(() => {
        animationFrame = animate(null, null, animationFrame);
        controls.start("visible");
      }, delay);
    }

    return () => {
      if (animationFrame) {
        cancelAnimationFrame(animationFrame);
      }
    };
  }, [isInView, animate, controls, delay]);

  const formattedCount = formatNumber ? count.toLocaleString() : count;

  const containerVariants = {
    hidden: { 
      opacity: 0,
      y: 20,
      scale: 0.95
    },
    visible: { 
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    }
  };

  return (
    <motion.div 
      ref={ref}
      className={styles.countUpContainer}
      variants={containerVariants}
      initial="hidden"
      animate={controls}
      whileHover={{ 
        y: -4,
        transition: { duration: 0.2 }
      }}
    >
      <div className={styles.count}>{formattedCount}</div>
      <div className={styles.label}>{label}</div>
    </motion.div>
  );
};

CountUp.propTypes = {
  end: PropTypes.number.isRequired,
  duration: PropTypes.number,
  label: PropTypes.string.isRequired,
  formatNumber: PropTypes.bool,
  delay: PropTypes.number
};

export default CountUp;
import PropTypes from 'prop-types';
import { 
  FaArrowLeft, 
  FaArrowRight, 
  FaTimes,
  FaEnvelope,
  FaPhone,
  FaFilePdf,
  FaLink,
  FaHeart,
  FaRegHeart
} from 'react-icons/fa';
import styles from './Popup.module.css';
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import axiosInstance from '../utils/axios';

const ClusterPopup = ({ clusterInfo, onClose, onNavigate, onListingUpdate }) => {
  const { listings, currentIndex } = clusterInfo;
  const currentListing = listings[currentIndex];
  const { token, user } = useAuth();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  // Check if user can view sensitive information
  const canViewSensitiveInfo = user && user.role_id !== 3;
  const shouldShowUpgradeButton = user && user.role_id === 3;

  const handleUpgrade = () => {
    navigate('/profile', { state: { initialMode: 'upgradePlan' } });
    onClose();
  };

  const toggleFavoriteMutation = useMutation(
    async (hash_id) => {
      try {
        const response = await axiosInstance.post(
          'saved_items/toggle_favorite',
          { hash_id },
          {
            headers: { Authorization: `Bearer ${token}` }
          }
        );
        return response.data;
      } catch (error) {
        console.error('API Error:', error.response?.data || error.message);
        throw error;
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('savedListings');
        // Create a complete updated listing object
        const updatedListing = {
          ...currentListing,
          hash_id: currentListing.hash_id,
          company_name: currentListing.company_name,
          address: currentListing.address,
          property_type: currentListing.property_type,
          transaction_type: currentListing.transaction_type,
          min_sq_ft_available: currentListing.min_sq_ft_available,
          max_sq_ft_available: currentListing.max_sq_ft_available,
          max_ac_available: currentListing.max_ac_available,
          min_ac_available: currentListing.min_ac_available,
          broker: currentListing.broker,
          email: currentListing.email,
          phone: currentListing.phone,
          brochure_link: currentListing.brochure_link,
          link: currentListing.link,
          isFavorited: !currentListing.isFavorited
        };

        if (onListingUpdate) {
          onListingUpdate(updatedListing, !currentListing.isFavorited);
        }
      },
      onError: (error) => {
        console.error('Error toggling favorite:', error.response?.data || error.message);
      },
    }
  );

  const handleFavoriteClick = () => {
    if (!token) {
      console.log('User is not authenticated.');
      return;
    }
    if (!currentListing?.hash_id) {
      console.error('No hash_id available for listing:', currentListing);
      return;
    }
    toggleFavoriteMutation.mutate(currentListing.hash_id);
  };

  return (
    <div className={styles.popup}>
      <div className={`${styles.popupHeader} ${!canViewSensitiveInfo ? styles.noCompanyName : ''}`}>
        {canViewSensitiveInfo && <h4>{currentListing.company_name}</h4>}
        <div className={styles.headerControls}>
          <button className={styles.popupClose} onClick={onClose}>
            <FaTimes />
          </button>
        </div>
      </div>
      <div className={styles.popupContent}>
        {canViewSensitiveInfo && (
          <p>
            <strong>Address:</strong> {currentListing.address}
          </p>
        )}
        <p>
          <strong>Property Type:</strong> {currentListing.property_type}
        </p>
        <p>
          <strong>Transaction Type:</strong> {currentListing.transaction_type}
        </p>
        <p>
          <strong>Max Sq Ft:</strong> {currentListing.max_sq_ft_available}
        </p>
        <p>
          <strong>Min Sq Ft:</strong> {currentListing.min_sq_ft_available}
        </p>
        {currentListing.max_ac_available && (
          <p>
            <strong>Max Acres:</strong> {currentListing.max_ac_available}
          </p>
        )}
        <p>
          <strong>Min Acres:</strong> {currentListing.min_ac_available}
        </p>
        {canViewSensitiveInfo ? (
          <>
            <p>
              <strong>Broker:</strong> {currentListing.broker}
            </p>
            <div className={styles.contactInfo}>
              <p className={styles.longText}>
                <FaEnvelope /> {currentListing.email}
              </p>
              <p className={styles.longText}>
                <FaPhone /> {currentListing.phone}
              </p>
            </div>
            <p>
              <a href={currentListing.brochure_link} target="_blank" rel="noopener noreferrer">
                <FaFilePdf /> Brochure
              </a>
            </p>
            <p>
              <a href={currentListing.link} target="_blank" rel="noopener noreferrer">
                <FaLink /> View Listing
              </a>
            </p>
          </>
        ) : (
          <p>Upgrade your account to view company name, broker, and contact information.</p>
        )}
      </div>
      <div className={styles.popupFooter}>
        <div className={styles.navigation}>
          <button 
            onClick={() => onNavigate('prev')} 
            disabled={currentIndex === 0}
          >
            <FaArrowLeft />
          </button>
          <span>{currentIndex + 1} / {listings.length}</span>
          <button 
            onClick={() => onNavigate('next')} 
            disabled={currentIndex === listings.length - 1}
          >
            <FaArrowRight />
          </button>
        </div>
        <div className={styles.actionButtons}>
          <button
            className={`${styles.popupFavorite} ${
              currentListing.isFavorited ? styles.favorited : ''
            }`}
            onClick={handleFavoriteClick}
          >
            {currentListing.isFavorited ? <FaHeart /> : <FaRegHeart />}
          </button>
          {shouldShowUpgradeButton && (
            <button className={styles.popupUpgrade} onClick={handleUpgrade}>
              Upgrade
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

ClusterPopup.propTypes = {
  clusterInfo: PropTypes.shape({
    listings: PropTypes.arrayOf(
      PropTypes.shape({
        hash_id: PropTypes.string.isRequired,
        company_name: PropTypes.string.isRequired,
        address: PropTypes.string.isRequired,
        property_type: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
        transaction_type: PropTypes.string.isRequired,
        max_sq_ft_available: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.array,
          PropTypes.number,
        ]),
        min_sq_ft_available: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.array,
          PropTypes.number,
        ]),
        max_ac_available: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.array,
          PropTypes.number,
        ]),
        min_ac_available: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.array,
          PropTypes.number,
        ]),
        broker: PropTypes.string,
        email: PropTypes.string,
        phone: PropTypes.string,
        brochure_link: PropTypes.string,
        link: PropTypes.string,
        isFavorited: PropTypes.bool,
      })
    ).isRequired,
    currentIndex: PropTypes.number.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onNavigate: PropTypes.func.isRequired,
  onListingUpdate: PropTypes.func,
};

export default ClusterPopup;
import React from 'react';
import PropTypes from 'prop-types';
import { useAuth } from '../AuthContext';
import MapContainer from './components/MapContainer';

const Map = ({ 
  searchResults, 
  searchParams, 
  isSearchActive, 
  onReset, 
  showingFavorites, 
  setLoading 
}) => {
  const { token, isLoggedIn, loading: authLoading } = useAuth();

  if (authLoading) {
    return null;
  }

  return (
    <MapContainer
      searchResults={searchResults}
      searchParams={searchParams}
      isSearchActive={isSearchActive}
      showingFavorites={showingFavorites}
      setLoading={setLoading}
      token={token}
      isLoggedIn={isLoggedIn}
    />
  );
};

Map.propTypes = {
  searchResults: PropTypes.shape({
    listings: PropTypes.arrayOf(
      PropTypes.shape({
        longitude: PropTypes.number,
        latitude: PropTypes.number,
        hash_id: PropTypes.string,
        company_name: PropTypes.string,
        address: PropTypes.string,
        property_type: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        transaction_type: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        max_sq_ft_available: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.array,
          PropTypes.number,
        ]),
        broker: PropTypes.string,
        email: PropTypes.string,
        phone: PropTypes.string,
        brochure_link: PropTypes.string,
        isFavorited: PropTypes.bool,
      })
    ),
    searchParams: PropTypes.object,
  }),
  searchParams: PropTypes.object,
  isSearchActive: PropTypes.bool,
  onReset: PropTypes.func,
  showingFavorites: PropTypes.bool,
  setLoading: PropTypes.func
};

export default Map;

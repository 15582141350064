import { Container } from 'react-bootstrap';
import styles from './styles.module.css';
import Hero from './Hero';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import WhatIsCanvassed from './WhatIsCanvassed';
import { IndustryBentoDemo } from './bento';
import Features from './Features';
import Services from './Services';
import CountUp from './CountUp';
import SubscriptionCards from './SubscriptionCards';
import { DotPatternDemo } from './dots';
import { useAuth } from '../AuthContext';
import { useEffect, useMemo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const LandingPage = () => {
  const { isLoggedIn } = useAuth();
  const location = useLocation();

  const handleScroll = useCallback((elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const scrollTo = searchParams.get('scrollTo');
    
    if (scrollTo === 'what-is-canvassed') {
      handleScroll('what-is-canvassed');
      // Clean up the URL
      window.history.replaceState({}, document.title, location.pathname);
    }
  }, [location, handleScroll]);

  // Structured data for SEO
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    "name": "Canvassed AI",
    "applicationCategory": "Commercial Real Estate Software",
    "operatingSystem": "Web-based",
    "description": "Comprehensive commercial real estate data platform providing market intelligence and property listings across the United States.",
    "offers": {
      "@type": "Offer",
      "price": "0",
      "priceCurrency": "USD"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.8",
      "ratingCount": "150"
    }
  };

  const countUpSection = useMemo(() => (
    <section aria-label="Statistics" className={styles.countUpSection}>
      <Container>
        <h2>At a Glance</h2>
        <div className={styles.countUpContainer} role="list">
          <div role="listitem">
            <CountUp end={140000} duration={2000} label="Listings+" />
          </div>
          <div role="listitem">
            <CountUp end={50} duration={2000} label="All States Covered" />
          </div>
          <div role="listitem">
            <CountUp end={400} duration={1500} label="Brokerages+" />
          </div>
        </div>
      </Container>
    </section>
  ), []);

  return (
    <>
      <Helmet>
        <title>Canvassed AI - Commercial Real Estate Data Platform | Market Intelligence</title>
        <meta name="description" content="Access 140,000+ verified commercial real estate listings, market analytics, and actionable insights. Transform your CRE decision making with comprehensive data." />
        <meta name="keywords" content="commercial real estate data, market intelligence, property listings, real estate analytics, CRE data platform, commercial property search" />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://canvassed.com/" />
        <meta property="og:title" content="Canvassed AI - Transform Your CRE Decision Making" />
        <meta property="og:description" content="Access comprehensive commercial real estate data and market intelligence. Get instant access to 140,000+ verified listings across all 50 states." />
        <meta property="og:image" content="https://canvassed.com/og-image.jpg" />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content="https://canvassed.com/" />
        <meta name="twitter:title" content="Canvassed AI - Transform Your CRE Decision Making" />
        <meta name="twitter:description" content="Access comprehensive commercial real estate data and market intelligence. Get instant access to 140,000+ verified listings across all 50 states." />
        <meta name="twitter:image" content="https://canvassed.com/twitter-image.jpg" />

        {/* Additional SEO tags */}
        <link rel="canonical" href="https://canvassed.com" />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Canvassed AI" />
        
        {/* Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      <div className={styles.pageWrapper}>
        <div className={styles.dotPatternWrapper}>
          <DotPatternDemo className={styles.dotPatternDemo} aria-hidden="true" />
        </div>
        <div className={styles.contentWrapper}>
          <Navbar />
          <div className={styles.landingPage}>
            <main id="main-content" role="main">
              <Hero />
              <WhatIsCanvassed />
              {countUpSection}
              <IndustryBentoDemo />
              <Features />
              <Services />
              {!isLoggedIn && <SubscriptionCards />}
            </main>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPage;

import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import styles from './services.module.css';
import { useInView } from '../Hooks/useInView';
import dataPortalIcon from '../assets/img/landing/data-portal.svg';
import apiAccessIcon from '../assets/img/landing/api-service.svg';
import dataDemandIcon from '../assets/img/landing/cart.svg';

const Services = () => {
    const [nextSectionRef, isNextSectionInView] = useInView({ threshold: 0.1 });

    const services = [
        { 
            name: 'Data Portal', 
            description: 'We provide a data portal for the more data driven users to access data in a more structured way.', 
            icon: dataPortalIcon 
        },
        { 
            name: 'API Access', 
            description: 'Robust API access for the more technical users and teams to ingest our data into their own systems.', 
            icon: apiAccessIcon 
        },
        { 
            name: 'Data on Demand', 
            description: 'Buy only what you need, when you need it with a la carte or bulk options.', 
            icon: dataDemandIcon 
        },
    ];

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.2,
                delayChildren: 0.3
            }
        }
    };

    return (
        <motion.section 
            id="services" 
            className={styles.services}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={containerVariants}
        >
            <Container fluid>
                <Row className={styles.serviceRow}>
                    <Col xs={12} className={styles.headerColumn}>
                        <motion.div 
                            className={`${styles.headerWrapper} ${isNextSectionInView ? styles.hidden : ''}`}
                            variants={{
                                hidden: { opacity: 0, y: -20 },
                                visible: { 
                                    opacity: 1, 
                                    y: 0,
                                    transition: { duration: 0.6, ease: "easeOut" }
                                }
                            }}
                        >
                            <h2>Services</h2>
                        </motion.div>
                    </Col>
                    <Col xs={12} className={styles.cardsColumn}>
                        <div className={styles.cardsContainer}>
                            {services.map((service, index) => (
                                <ServiceCard 
                                    key={index}
                                    service={service}
                                    index={index}
                                />
                            ))}
                        </div>
                    </Col>
                </Row>
            </Container>
            <div ref={nextSectionRef} className={styles.observerTarget}></div>
        </motion.section>
    );
};

const ServiceCard = ({ service, index }) => {
    const [cardRef] = useInView({ threshold: 0.1, triggerOnce: true });

    const cardVariants = {
        hidden: { 
            opacity: 0, 
            y: 20,
            scale: 0.95
        },
        visible: { 
            opacity: 1, 
            y: 0,
            scale: 1,
            transition: {
                duration: 0.5,
                delay: index * 0.1,
                ease: "easeOut"
            }
        }
    };

    const iconVariants = {
        hidden: { scale: 0.8, opacity: 0 },
        visible: { 
            scale: 1, 
            opacity: 1,
            transition: {
                duration: 0.3,
                delay: index * 0.1 + 0.2
            }
        },
        hover: { 
            scale: 1.1,
            transition: { duration: 0.2 }
        }
    };

    return (
        <motion.div
            ref={cardRef}
            className={styles.serviceCard}
            variants={cardVariants}
            whileHover={{ 
                y: -4,
                transition: { duration: 0.2 }
            }}
        >
            <motion.div
                className={styles.serviceIcon}
                variants={iconVariants}
                whileHover="hover"
            >
                {typeof service.icon === 'string' ? 
                    <img src={service.icon} alt={`${service.name} icon`} className={styles.svgIcon} /> :
                    <service.icon className={styles.svgIcon} />
                }
            </motion.div>
            <motion.div 
                className={styles.serviceContent}
                variants={{
                    hidden: { opacity: 0, y: 10 },
                    visible: { 
                        opacity: 1, 
                        y: 0,
                        transition: { duration: 0.3, delay: index * 0.1 + 0.3 }
                    }
                }}
            >
                <h3>{service.name}</h3>
                <p>{service.description}</p>
            </motion.div>
        </motion.div>
    );
};

ServiceCard.propTypes = {
    service: PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        icon: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.elementType
        ]).isRequired,
    }).isRequired,
    index: PropTypes.number.isRequired,
};

export default Services;

import PropTypes from 'prop-types';
import styles from './Dataportal.module.css';

// Define columns for restricted users (role_id 3)
const RESTRICTED_COLUMNS = [
  'City',
  'County',
  'State',
  'ZIP Code',
  'Last Updated',
  'Property Type',
  'Transaction Type',
  'Date Created',
  'Days on Market',
  'Min Sq Ft Available',
  'Max Sq Ft Available',
  'Min Acres Available',
  'Max Acres Available'
]; // These should match the column names from the backend

// Example formatCellData function
const formatCellData = (cell, column) => {
  // Don't format ZIP Code column as date
  if (column === 'ZIP Code') {
    return cell;
  }
  
  if (typeof cell === 'string' && Date.parse(cell)) {
    return new Date(cell).toLocaleDateString();
  }
  return cell;
};

const Dataportal = ({ data, loading, error, userRole }) => {
  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!data || data.length === 0) {
    return (
      <div className={styles.dataportalWrapper}>
        <p>No data to display. Use the search controls above to find records.</p>
      </div>
    );
  }

  // Get all available columns from the first data row
  const allColumns = Object.keys(data[0]);
  
  // Determine visible columns based on user role
  // role_id 3 gets restricted columns, all other roles get full access
  const visibleColumns = userRole === 3 
    ? allColumns.filter(column => RESTRICTED_COLUMNS.includes(column))
    : allColumns;

  return (
    <div className={styles.dataportalWrapper}>
      <div className={styles.tableWrapper}>
        <table className={styles.dataTable}>
          <thead>
            <tr>
              {visibleColumns.map((header) => (
                <th key={header}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr key={`row-${index}`}>
                {visibleColumns.map((column, cellIndex) => (
                  <td key={`cell-${index}-${cellIndex}`}>
                    {formatCellData(row[column], column)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

Dataportal.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  userRole: PropTypes.number, // Expecting a number for role_id
};

export default Dataportal;

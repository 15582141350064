import styles from './SignUpButton.module.css';
import { FaRocket } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const SignUpButton = () => {
  return (
    <Link to="/signup" className={styles.buttonLink}>
      <button className={styles.button}>
        <FaRocket /> Sign Up For Free
      </button>
    </Link>
  );
};

export default SignUpButton;

import { useEffect, useRef, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import ErrorBoundary from './Errorboundary';
import { useMapState } from '../hooks/useMapState';
import { useMapEvents } from '../hooks/useMapEvents';
import { useMapData } from '../hooks/useMapData';
import { useMapPopup } from '../hooks/useMapPopup';
import { mapService } from '../services/mapService';
import MapControls from './MapControls';
// import MapCoordinates from './MapCoordinates';
import MapPopup from './MapPopup';
import { MAPBOX_ACCESS_TOKEN } from '../constants';
import styles from '../map_page.module.css';
import debounce from 'lodash/debounce';

mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;

const MapContainer = ({ 
  searchResults, 
  searchParams, 
  isSearchActive, 
  showingFavorites, 
  setLoading,
  token,
  isLoggedIn
}) => {
  const isSearchActiveRef = useRef(isSearchActive);
  const initializationAttempts = useRef(0);
  const [initError, setInitError] = useState(null);
  const [isInitializing, setIsInitializing] = useState(true);
  const mapInitializedRef = useRef(false);
  const pendingOperationsQueue = useRef([]);
  const initialFetchDoneRef = useRef(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const mapStableRef = useRef(false);
  const initialFetchStartedRef = useRef(false);

  const {
    mapContainer,
    map,
    coordinates,
    mapLoaded,
    setMapLoadedState,
    mapLoadedRef,
    popup,
    moveState
  } = useMapState();

  const {
    savedListings,
    fetchSavedListings,
    fetchListings,
    isLoading: dataLoading,
    error: dataError,
    retry: retryDataFetch
  } = useMapData({ 
    map, 
    token, 
    setLoading,
    isSearchActive
  });

  const executeQueuedOperations = useCallback(() => {
    while (pendingOperationsQueue.current.length > 0) {
      const operation = pendingOperationsQueue.current.shift();
      operation();
    }
  }, []);

  const waitForMapStable = useCallback((callback) => {
    const currentMap = map.current;
    if (!currentMap) return;

    const checkStable = () => {
      if (currentMap.isMoving() || currentMap.isZooming() || currentMap.isRotating()) {
        mapStableRef.current = false;
        requestAnimationFrame(checkStable);
      } else {
        setTimeout(() => {
          if (!currentMap.isMoving() && !currentMap.isZooming() && !currentMap.isRotating()) {
            mapStableRef.current = true;
            callback();
          }
        }, 100);
      }
    };
    checkStable();
  }, [map]);

  // Modified fetch handlers with stability check
  const handleFetchListings = useCallback(() => {
    if (!mapInitializedRef.current) {
      pendingOperationsQueue.current.push(() => handleFetchListings());
      return;
    }

    setIsDataLoading(true);
    waitForMapStable(() => {
      fetchListings()
        .then(() => {
          setIsDataLoading(false);
        })
        .catch(error => {
          console.error('Error fetching listings:', error);
          if (error.retryable !== false) {
            setTimeout(handleFetchListings, 3000);
          } else {
            setIsDataLoading(false);
          }
        });
    });
  }, [fetchListings, waitForMapStable]);

  const debouncedFetchSavedListings = useCallback(
    debounce(async () => {
      if (!isLoggedIn || dataLoading) return;
      
      try {
        await fetchSavedListings();
      } catch (error) {
        console.error('Error fetching saved listings:', error);
      }
    }, 1000, { leading: true, trailing: false, maxWait: 2000 }),
    [fetchSavedListings, isLoggedIn, dataLoading]
  );

  const MAX_RETRIES = 3;
  const INITIAL_RETRY_DELAY = 1000; // 1 second

  const fetchSavedListingsWithRetry = useCallback(async (retryCount = 0) => {
    if (!isLoggedIn || dataLoading || initialFetchDoneRef.current) return;
    if (!mapInitializedRef.current || !mapStableRef.current) {
      console.log('Map not ready for saved listings fetch, queuing operation');
      pendingOperationsQueue.current.push(() => fetchSavedListingsWithRetry(retryCount));
      return;
    }
    
    setIsDataLoading(true);
    try {
      await fetchSavedListings();
      initialFetchDoneRef.current = true;
      setIsDataLoading(false);
    } catch (error) {
      console.error(`Error in fetch of saved listings (attempt ${retryCount + 1}):`, error);
      
      if (retryCount < MAX_RETRIES) {
        const delay = INITIAL_RETRY_DELAY * Math.pow(2, retryCount);
        console.log(`Retrying in ${delay}ms...`);
        
        setTimeout(() => {
          fetchSavedListingsWithRetry(retryCount + 1);
        }, delay);
      } else {
        console.error('Max retries exceeded for saved listings fetch');
        initialFetchDoneRef.current = false;
        setIsDataLoading(false);
      }
    }
  }, [fetchSavedListings, isLoggedIn, dataLoading, mapInitializedRef, mapStableRef]);

  const handleFetchSavedListings = useCallback(() => {
    if (!mapInitializedRef.current) {
      pendingOperationsQueue.current.push(() => handleFetchSavedListings());
      return;
    }

    waitForMapStable(() => {
      // Only use debounce for subsequent fetches, not initial load
      if (initialFetchDoneRef.current) {
        debouncedFetchSavedListings();
      } else {
        fetchSavedListingsWithRetry();
      }
    });
  }, [debouncedFetchSavedListings, fetchSavedListingsWithRetry, waitForMapStable]);

  // Add effect to retry failed initial fetch on map interaction
  useEffect(() => {
    if (map.current && !initialFetchDoneRef.current && isLoggedIn) {
      const retryOnInteraction = () => {
        if (!initialFetchDoneRef.current) {
          fetchSavedListingsWithRetry();
        }
      };

      map.current.on('moveend', retryOnInteraction);
      map.current.on('zoomend', retryOnInteraction);

      return () => {
        if (map.current) {
          map.current.off('moveend', retryOnInteraction);
          map.current.off('zoomend', retryOnInteraction);
        }
      };
    }
  }, [map, isLoggedIn, fetchSavedListingsWithRetry]);

  const {
    handleClosePopup,
    handleNavigate,
    handleClusterClick,
    handlePointClick
  } = useMapPopup({
    map,
    setPopupInfo: popup.setInfo,
    setPopupScreenPosition: popup.setScreenPosition
  });

  // Map event handlers
  useMapEvents({
    map,
    mapLoadedRef,
    isSearchActiveRef,
    moveState,
    setPopupInfo: popup.setInfo,
    setPopupScreenPosition: popup.setScreenPosition,
    onFetchListings: handleFetchListings,
    onFetchSavedListings: handleFetchSavedListings,
    onClusterClick: handleClusterClick,
    onPointClick: handlePointClick
  });

  // Update search active ref when prop changes
  useEffect(() => {
    const wasSearchActive = isSearchActiveRef.current;
    isSearchActiveRef.current = isSearchActive;

    if (!isSearchActive && wasSearchActive) {
      // When exiting search mode, use current bounds
      if (map.current) {
        const currentBounds = map.current.getBounds();
        console.log('Reset clicked - Starting reset process');

        // First, fit the map to current bounds to trigger a movement
        map.current.fitBounds(currentBounds, {
          padding: { top: 50, bottom: 50, left: 50, right: 50 },
          duration: 0  // Immediate fit
        });

        // Then check if map has settled
        const checkMapState = () => {
          if (map.current.isMoving() || map.current.isZooming() || map.current.isRotating()) {
            requestAnimationFrame(checkMapState);
          } else {
            // Get the new bounds after movement
            const newBounds = map.current.getBounds();
            console.log('Map reset complete - New bounds:', newBounds.toString());
            
            // Trigger a slight zoom to ensure bounds update
            map.current.easeTo({
              zoom: map.current.getZoom() + 0.000001,
              duration: 0,
              callback: () => {
                // Get final bounds after all movements
                const finalBounds = map.current.getBounds();
                console.log('Final map state after reset:', {
                  bounds: finalBounds.toString(),
                  center: map.current.getCenter(),
                  zoom: map.current.getZoom()
                });
                handleFetchListings();
              }
            });
          }
        };

        // Start checking map state
        checkMapState();
      }
    }
  }, [isSearchActive, map, handleFetchListings]);

  // Modified map initialization
  useEffect(() => {
    if (map.current || initializationAttempts.current >= 3) return;

    const initializeMap = async () => {
      try {
        setInitError(null);
        setLoading(true);
        setIsInitializing(true);
        setIsDataLoading(true);
        
        map.current = mapService.initializeMap(mapContainer.current, coordinates);
        const currentMap = map.current;

        currentMap.on('load', () => {
          console.log('Map initialized and loaded');
          setMapLoadedState(true);
          moveState.updateCurrentBounds();

          waitForMapStable(() => {
            mapInitializedRef.current = true;
            console.log('Map fully stabilized');
            
            executeQueuedOperations();
            
            setLoading(false);
            setIsInitializing(false);
          });
        });

        currentMap.on('error', (e) => {
          console.error('Map load error:', e);
          setInitError(e.error);
          setIsInitializing(false);
          setIsDataLoading(false);
        });
      } catch (error) {
        console.error('Map initialization failed:', error);
        setInitError(error);
        initializationAttempts.current++;
        setIsInitializing(false);
        setIsDataLoading(false);
      }
    };

    initializeMap();
  }, [coordinates, isSearchActive, moveState, waitForMapStable, executeQueuedOperations]);

  // Handle search results updates
  useEffect(() => {
    const currentMap = map.current;
    if (!currentMap || !currentMap.getSource('listings') || !mapLoadedRef.current) return;

    // Clear existing listings when search is active but no results
    if (isSearchActive && (!searchResults?.listings || searchResults.listings.length === 0)) {
      mapService.updateListings(currentMap, [], savedListings);
      return;
    }

    // Update map with search results
    if (searchResults?.listings?.length > 0) {
      // Prevent any pending fetches before updating search results
      if (moveState.current) {
        moveState.current.skipNextFetch = true;
      }
      mapService.updateListings(currentMap, searchResults.listings, savedListings);
      // Only fit to search results if we're in search mode
      if (isSearchActive) {
        mapService.fitMapToSearchResults(currentMap, searchResults.listings, searchParams);
      }
    }
    // Remove the duplicate fetch here since it's handled in the reset effect
    setLoading(false);
  }, [searchResults, searchParams, isSearchActive, savedListings]);

  // Handle favorites toggle
  useEffect(() => {
    const currentMap = map.current;
    if (!currentMap || !mapLoaded) return;

    if (currentMap.isStyleLoaded()) {
      mapService.toggleLayerVisibility(currentMap, showingFavorites);
    } else {
      const styleLoadHandler = () => {
        mapService.toggleLayerVisibility(currentMap, showingFavorites);
      };
      currentMap.once('style.load', styleLoadHandler);
      
      // Cleanup
      return () => {
        currentMap.off('style.load', styleLoadHandler);
      };
    }
  }, [map, showingFavorites, mapLoaded]);

  // Handle listing updates
  const handleListingUpdate = useCallback((listing, isSaved) => {
    if (map.current) {
      mapService.updateSingleListing(map.current, listing, isSaved);
    }
  }, [map]);

  // Single effect for initial fetch - modified to check map stability
  useEffect(() => {
    if (isLoggedIn && 
        !initialFetchDoneRef.current && 
        !initialFetchStartedRef.current && 
        mapInitializedRef.current && 
        mapStableRef.current && 
        !isSearchActive) {
      console.log('Map is stable and initialized, fetching saved listings');
      initialFetchStartedRef.current = true;
      fetchSavedListingsWithRetry();
    }
  }, [isLoggedIn, fetchSavedListingsWithRetry, mapInitializedRef.current, mapStableRef.current, isSearchActive]);

  // Cleanup debounced function
  useEffect(() => {
    return () => {
      debouncedFetchSavedListings.cancel();
    };
  }, [debouncedFetchSavedListings]);

  // Show error state
  if (initError) {
    return (
      <div className="map-error">
        <h3>Error loading map</h3>
        <p>{initError.message}</p>
        <button onClick={() => {
          initializationAttempts.current = 0;
          setInitError(null);
        }}>
          Retry
        </button>
      </div>
    );
  }

  return (
    <ErrorBoundary fallback={<div>Something went wrong with the map. Please refresh the page.</div>}>
      <div style={{ width: '100%', height: '100%', position: 'relative' }}>
        {(isDataLoading || isInitializing) && <div className={styles.spinner} />}
        {/* <MapCoordinates coordinates={coordinates} /> */}
        
        <div
          ref={mapContainer}
          style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
        />

        <MapControls 
          map={map} 
          center={coordinates} 
        />

        <MapPopup
          popupInfo={popup.info}
          popupScreenPosition={popup.screenPosition}
          mapContainerRef={mapContainer}
          onClose={handleClosePopup}
          onNavigate={handleNavigate}
          onListingUpdate={handleListingUpdate}
        />

        {dataError && (
          <div className="data-error-overlay">
            <p>Error loading data</p>
            <button onClick={retryDataFetch}>Retry</button>
          </div>
        )}
      </div>
    </ErrorBoundary>
  );
};

MapContainer.propTypes = {
  searchResults: PropTypes.shape({
    listings: PropTypes.arrayOf(
      PropTypes.shape({
        longitude: PropTypes.number,
        latitude: PropTypes.number,
        hash_id: PropTypes.string,
        company_name: PropTypes.string,
        address: PropTypes.string,
        property_type: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        transaction_type: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        max_sq_ft_available: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.array,
          PropTypes.number,
        ]),
        broker: PropTypes.string,
        email: PropTypes.string,
        phone: PropTypes.string,
        brochure_link: PropTypes.string,
        isFavorited: PropTypes.bool,
      })
    ),
    searchParams: PropTypes.object,
  }),
  searchParams: PropTypes.object,
  isSearchActive: PropTypes.bool,
  showingFavorites: PropTypes.bool,
  setLoading: PropTypes.func.isRequired,
  token: PropTypes.string,
  isLoggedIn: PropTypes.bool.isRequired,
};

export default MapContainer;
import axios from '../../utils/axios';

export const listingService = {
  async fetchSavedListings(token) {
    if (!token) {
      console.warn('No authentication token provided');
      return [];
    }

    try {
      const response = await axios.get('saved_items/get_saved_listings', {
        headers: { 
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      
      if (!response.data) {
        console.warn('No data received from saved listings endpoint');
        return [];
      }

      return response.data;
    } catch (error) {
      if (error.response?.status === 401) {
        console.warn('Token expired or invalid, clearing saved listings');
        return [];
      }
      console.error('Error fetching saved listings:', error);
      error.retryable = error.response?.status < 500; // Only retry server errors
      throw error;
    }
  },

  async fetchListingsInBounds(bounds) {
    if (!bounds) {
      throw new Error('Map bounds are required');
    }
    try {
      const response = await axios.get(
        `map/listings?ne_lat=${bounds.getNorthEast().lat}&ne_lng=${bounds.getNorthEast().lng}&sw_lat=${bounds.getSouthWest().lat}&sw_lng=${bounds.getSouthWest().lng}`
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching listings in bounds:', error);
      error.retryable = error.response?.status < 500;
      throw error;
    }
  }
};